
import { Component, Prop, Vue } from 'vue-property-decorator';
import { locationsService } from '@/services/locations.service';
import { Location } from '@/models/location';
import { hasCurrentUserPermission } from '@/utils/permissionUtils';
import { UserType } from '@/models/userType';
import { Customer } from '@/models/tenant';

@Component
export default class LocationEditionModal extends Vue {
  @Prop({ required: true })
  private location!: Location;

  @Prop({ required: true })
  private customer!: Customer;

  private isSubmitting = false;

  private async onSubmit() {
    this.isSubmitting = true;
    locationsService
      .update(this.location)
      .then(() => {
        this.$emit('save');
        this.$emit('close');
      })
      .finally(() => (this.isSubmitting = false));
  }

  private get canEditLocation(): boolean {
    return hasCurrentUserPermission(UserType.SubsidiaryAdmin);
  }

  private get isFormValid() {
    if (this.location && this.$refs.form) {
      const refForm = this.$refs.form as any;
      return refForm.checkValidity();
    }
    return false;
  }

  private handleChange(event: any): void {
    this.location = { ...this.location, [event.target.name]: event.target.value };
  }
}
