
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Device } from '@/models/device';
import { devicesService } from '@/services/devices.service';
import { Tenant } from '@/models/tenant';
import { Mode } from '@/models/enums/Mode';
import { UserType } from '@/models/userType';
import { hasCurrentUserPermission } from '@/utils/permissionUtils';
import { locationsService } from '@/services/locations.service';
import { Location } from '@/models/location';
import DeviceFormModal from '@/components/devices/DeviceFormModal.vue';
import DeviceSASModal from '@/components/devices/DeviceSASModal.vue';
import TableActionButton from '@/components/tableWithActions/TableActionButton.vue';
import TableWithActions from '@/components/tableWithActions/TableWithActions.vue';
import { matchIgnoringCase } from '@/utils/table';
import { ShiftModel } from '@/models/shiftModel';
import { shiftModelsService } from '@/services/shiftModels.service';
import i18n from '@/i18n';
import moment from 'moment';
import { getAllDevices, getAllDevicesByCustomerId } from '@/use-cases/devices';
import { modalService } from '@/library-services/modal.service';
import { dialogService } from '@/library-services/dialog.service';

@Component({
  computed: {
    Mode: () => Mode,
  },
  components: {
    TableActionButton,
    TableWithActions,
  },
})
export default class Devices extends Vue {
  // This tenant will be undefined when the table is accessed directly
  // through the cog menu
  @Prop()
  private tenant?: Tenant;

  private devices: Device[] = [];
  private locations: Location[] = [];
  private shiftModels: ShiftModel[] = [];

  private isLoading = false;

  private async created() {
    // I set isLoading to true here because I don't want the table to be usable until all data has been loaded.
    // However, I don't want to set isLoading to false here because updateDevices() gets called in another place too,
    // and I want updateDevices() to handle isLoading in those cases
    this.isLoading = true;

    this.shiftModels = await shiftModelsService.get(this.tenant?.id);
    this.locations = await locationsService.get(this.tenant?.id);

    await this.updateDevices();
  }

  private async getWithStatuses(): Promise<Device[]> {
    const deviceStatuses = await devicesService.getDevicesStatus(this.tenant?.id);

    return this.devices.map((device) => {
      const deviceStatus = deviceStatuses.find((status) => device.deviceId === status.deviceId);

      if (deviceStatus) {
        device.status = deviceStatus;
      } else {
        device.setStatusToNotAvailable();
      }

      return device;
    });
  }

  private async openDeviceFormModal(mode: Mode, deviceToEdit?: Device) {
    modalService.open({
      parent: this,
      component: DeviceFormModal,
      events: {
        save: () => this.updateDevices(),
      },
      props: {
        mode,
        tenant: this.tenant,
        deviceIn: deviceToEdit,
      },
    });
  }

  private async updateDevices() {
    this.isLoading = true;

    try {
      this.devices = this.tenant?.id
        ? await getAllDevicesByCustomerId(this.tenant?.id)
        : await getAllDevices();
    } finally {
      this.isLoading = false;
    }

    this.devices = await this.getWithStatuses();
  }

  private async deleteDevice(id: number) {
    await devicesService.delete(id).then(() => {
      this.devices = this.devices.filter((x) => x.id !== id);
      devicesService.store.updateAll([...this.devices]);
    });
  }

  private confirmDelete(device: Device): void {
    dialogService.confirmWithPromise({
      title: this.$i18n.t('dialog.confirm_delete_title').toString(),
      message: this.$i18n
        .t('confirmation.message', {
          action: this.$i18n.t('action.delete').toString().toLowerCase(),
          entity: this.$i18n.t('device.name').toString().toLowerCase(),
          name: device.deviceId,
        })
        .toString(),
      confirmText: this.$i18n.t('dialog.confirm_delete').toString(),
      cancelText: this.$i18n.t(`dialog.confirm_cancel`).toString(),
      type: 'is-danger',
      onConfirm: async () => await this.deleteDevice(device.id),
    });
  }

  private async getSas() {
    modalService.open({
      parent: this,
      component: DeviceSASModal,
      canCancel: ['outside'],
      props: {
        tenantId: this.tenant!.id,
      },
    });
  }

  private getLocationName(locationId: number): string {
    const location = this.locations.find((location) => location.id === locationId);

    return location!.name;
  }

  private getShiftModelName(shiftModelId?: number): string {
    const shiftModel = this.shiftModels.find((shiftModel) => shiftModel.id === shiftModelId);

    return shiftModel!.name;
  }

  private matchesLocation(device: Device, searchText: string): boolean {
    return matchIgnoringCase(this.getLocationName(device.locationId), searchText);
  }

  private matchesShiftModel(device: Device, searchText: string): boolean {
    if (!device.shiftModelId) {
      return matchIgnoringCase(i18n.t('shift_model.unassigned').toString(), searchText);
    }
    return matchIgnoringCase(this.getShiftModelName(device.shiftModelId), searchText);
  }

  private get canCreateAndDeleteEquipment(): boolean {
    return hasCurrentUserPermission(UserType.SubsidiaryAdmin);
  }

  private get canEditEquipment(): boolean {
    return this.canCreateAndDeleteEquipment || hasCurrentUserPermission(UserType.CustomerAdmin);
  }

  private get canGetSmbsSas(): boolean {
    return hasCurrentUserPermission(UserType.SubsidiaryAdmin);
  }

  private searchLastStatus(device: Device, filter: string) {
    return matchIgnoringCase(device.status ? device.status.lastSeenAgoText : '', filter);
  }

  private sortByLastStatus(a: Device, b: Device, isAsc: boolean) {
    if (a.status && a.status.lastSeen && b.status && b.status.lastSeen) {
      const dateA = moment(a.status.lastSeen);
      const dateB = moment(b.status.lastSeen);
      if (dateA.isBefore(dateB)) {
        return isAsc ? -1 : 1;
      } else if (dateA.isAfter(dateB)) {
        return isAsc ? 1 : -1;
      }
    }
    return 0;
  }

  private searchLastStatusText(device: Device, filter: string) {
    return matchIgnoringCase(device.status ? device.status.statusText : '', filter);
  }

  private sortByLastStatusText(a: Device, b: Device, isAsc: boolean) {
    const translatedTypeA: string = a.status ? a.status.statusText : '';
    const translatedTypeB: string = b.status ? b.status.statusText : '';
    return isAsc
      ? translatedTypeA.localeCompare(translatedTypeB)
      : translatedTypeB.localeCompare(translatedTypeA);
  }
}
