// --------------------------------------------------------------------------------
// <copyright file="number.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import i18n from '@/i18n';
import { currencyCode } from '@/utils/currency';
import { currentLocale } from '@/utils/i18n';

export function createDecimalFormatter(maximumFractionDigits = 2): (value: any) => string {
  return (value) => decimalFormatter(value, maximumFractionDigits);
}

export function decimalFormatter(value: any, maximumFractionDigits = 2) {
  return i18n.n(value, { maximumFractionDigits });
}

/**
 * Returns a compact representation of value by reducing the digits with an
 * abbreviation.
 *
 * Example: 1200 -> 1.2K
 *
 * @param value Number to format in abbreviated form.
 * @param maximumFractionDigits The maximum number of decimal places to return.
 */
export function abbreviateNumber(value: number, maximumFractionDigits?: number): string {
  return new Intl.NumberFormat(currentLocale(), {
    notation: 'compact',
    maximumFractionDigits,
  }).format(value);
}

export function abbreviateNumberWithCurrency(n: number): string {
  return new Intl.NumberFormat(currentLocale(), {
    notation: 'compact',
    style: 'currency',
    currency: currencyCode(),
  }).format(n);
}

export function numberWithCurrency(n: number): string {
  return new Intl.NumberFormat(currentLocale(), {
    style: 'currency',
    currency: currencyCode(),
  }).format(n);
}

export function formatPercentage(percentage: number, maximumFractionDigits: number = 2): string {
  const adjustedPercentage = percentage < 0 && percentage > -1 // avoid -0 %
    ? 0
    : percentage;

  return new Intl.NumberFormat(currentLocale(), {
    style: 'percent',
    maximumFractionDigits,
    // percent style expects a fraction of one, so we have to divide by 100
  }).format(adjustedPercentage / 100);
}
