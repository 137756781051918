// --------------------------------------------------------------------------------
// <copyright file="bendingToolChangesKpiWidgetGenerator.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { BEND_SUMMARY_BASE_COLOR } from '@/utils/color';
import { ProcedureName } from './abstract/chartGenerator';
import { PieChartKpiWidgetGenerator } from './abstract/pieChartKpiWidgetGenerator';
import { KpiWidgetData } from './chartsData';
import { FilterTimeSpanEnum } from '../enums/FilterTimeSpanEnum';
import { metricsService } from '@/services/metrics.service';

export class BendingToolChangesKpiWidgetGenerator extends PieChartKpiWidgetGenerator {
  constructor(procedure: ProcedureName, tenantIdDh: number) {
    const mainColor = BEND_SUMMARY_BASE_COLOR;
    super(procedure, tenantIdDh, mainColor);
  }

  override async getData(
    selectedDevices: string[],
    selectedShifts: number[],
    timeSpan: FilterTimeSpanEnum | [string, string],
  ): Promise<KpiWidgetData[] | null> {
    return await metricsService.getBendingDevicesMetrics<KpiWidgetData[]>(
      this.procedure,
      {
        customerIdDh: this.tenantIdDh,
        deviceStringIds: selectedDevices,
        shiftIds: selectedShifts,
        timeSpan: timeSpan as FilterTimeSpanEnum,
      },
      this.controller,
    );
  }
}
