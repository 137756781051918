
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { getStateColour } from '@/utils/color';
import TableWithActions2 from '@/components/tableWithActions/TableWithActions2.vue';
import TableActionButton from '@/components/tableWithActions/TableActionButton.vue';
import CellWithSubtitle from '@/components/tableWithActions/CellWithSubtitle.vue';
import { isEmpty } from '@/utils/misc';
import { Device } from '@/models/device';
import { devicesService } from '@/services/devices.service';
import WidgetDefinition from '@/models/Charts/widgetDefinition';
import {
  INTRADAY_GANTT_STATES,
  LaserGanttState,
  LaserGanttTableData,
  LaserGroupedGanttTableData,
} from '@/models/Charts/chartsData';
import { IntradayAvailabilityGanttGenerator } from '@/models/Charts/gantt/intradayAvailabilityGanttGenerator';
import LaserGanttChartFilters from './LaserGanttChartFilters.vue';
import GanttChartRow from '../GanttChartRow.vue';
import GanttTimelineRow from '../GanttTimelineRow.vue';
import {
  dataGroupedByDevice,
  filterDeviceDataByStates,
  getDefaultZoomValues,
  getLastState,
  getTotalLengthOfState,
} from '@/utils/gantt';

@Component({
  components: {
    CellWithSubtitle,
    LaserGanttChartFilters,
    GanttChartRow,
    TableWithActions2,
    TableActionButton,
    GanttTimelineRow,
  },
  methods: {
    getStateColour,
    isEmpty,
    getTotalLengthOfState,
    filterDeviceDataByStates,
    getLastState,
  },
})
export default class LaserGanttChartTable extends Vue {
  @Prop({ required: true })
  private widgetDefinition!: WidgetDefinition;

  @Prop({ required: true })
  private data!: LaserGanttTableData[] | null;

  @Prop({ required: true })
  private loading!: boolean;

  @Prop({ required: true })
  private generator!: IntradayAvailabilityGanttGenerator;

  private states: LaserGanttState[] = INTRADAY_GANTT_STATES;

  private defaultZoomValues: [Date, Date] = getDefaultZoomValues();
  private zoomValues: [Date, Date] = getDefaultZoomValues();

  private showGantt = true;

  private get deviceIds(): string[] {
    return this.widgetDefinition.deviceIds;
  }

  private get dataGroupedByDevice(): LaserGroupedGanttTableData[] {
    return dataGroupedByDevice(this.data, this.zoomValues, this.deviceIds);
  }

  private get devices(): Device[] {
    return devicesService.store.get().filter((x) => this.deviceIds.includes(x.deviceId));
  }

  private get ganttKey(): string {
    return this.states.join('_');
  }

  private getDeviceNameByDeviceId(deviceid: string): string {
    return this.devices.find((x) => x.deviceId === deviceid)!.name;
  }

  private async refresh() {
    this.$emit('refresh');
  }

  private onFiltersChanged(
    states: LaserGanttState[],
    zoomValues: [Date, Date],
    areApplying: boolean,
    next: (filterValues: Record<string, any>, areApplying: boolean) => void,
  ) {
    this.states = states;
    this.zoomValues = zoomValues;
    next({}, areApplying);
  }

  @Watch('data')
  private watchData() {
    this.defaultZoomValues = getDefaultZoomValues();
    this.zoomValues = this.defaultZoomValues;
  }
}
