// --------------------------------------------------------------------------------
// <copyright file="ganttChartGenerator.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { ChartGenerator, ProcedureName } from '@/models/Charts/abstract/chartGenerator';
import i18n from '@/i18n';
import { getStateColour } from '@/utils/color';
import { FilterTimeSpanEnum } from '@/models/enums/FilterTimeSpanEnum';
import { Device } from '../device';
import { CustomSeriesRenderItem, CustomSeriesRenderItemAPI, graphic, EChartsOption } from 'echarts';
import moment from 'moment';
import { GeneratorParams } from './generatorParams';
import { mockGanttData } from './mockWidgetSelectorData';
import { isEmpty } from '@/utils/misc';
import { metricsService } from '@/services/metrics.service';
import { formatDuration } from '@/utils/dates';
import { INTRADAY_GANTT_STATES, LaserGanttData } from './chartsData';
import { devicesService } from '@/services/devices.service';

export class GanttChartGenerator extends ChartGenerator<LaserGanttData[]> {
  constructor(procedure: ProcedureName, public tenantIdDh: number) {
    super(procedure);
  }

  override getData(
    selectedDevices: string[],
    selectedShifts: number[],
    timeSpan: FilterTimeSpanEnum | [string, string],
  ) {
    selectedDevices =
      selectedDevices.length !== 0 ? selectedDevices : devicesService.store.cutDevicesIds();
    return metricsService.getDevicesMetrics<LaserGanttData[]>(
      this.procedure,
      {
        tenantIdDh: this.tenantIdDh,
        deviceIds: selectedDevices,
        shifts: selectedShifts,
        timeSpan: timeSpan as FilterTimeSpanEnum,
      },
      this.controller,
    );
  }

  override updateOptions(
    data: LaserGanttData[],
    parameters: GeneratorParams = {},
    prevOptions?: EChartsOption,
  ): EChartsOption {
    const allDevices = devicesService.store.get();
    const resultDeviceIds: string[] = [...new Set<string>(data.map((x) => x.deviceid))];
    const devices: Device[] = allDevices.filter(
      (x: Device) => resultDeviceIds.indexOf(x.deviceId) !== -1,
    );

    const seriesData: any[] = [];
    for (let index = 0; index < devices.length; index++) {
      const device = devices[index];
      const entryList = data
        .filter((x) => x.deviceid === device.deviceId)
        .sort((a, b) => {
          if (a.start_timestamp === b.start_timestamp) {
            return a.end_timestamp - b.end_timestamp;
          } else {
            return a.start_timestamp - b.start_timestamp;
          }
        });
      const mergedEntries: LaserGanttData[] = [];
      for (const entry of entryList) {
        if (
          mergedEntries.length === 0 ||
          mergedEntries[mergedEntries.length - 1].state !== entry.state ||
          entry.start_timestamp - mergedEntries[mergedEntries.length - 1].end_timestamp > 1
        ) {
          mergedEntries.push(entry);
        } else {
          const last = mergedEntries.pop()!;
          const newEntry: LaserGanttData = {
            ...last,
            end_timestamp: entry.end_timestamp,
            duration: entry.end_timestamp - last.start_timestamp,
          };
          mergedEntries.push(newEntry);
        }
      }

      seriesData.push(
        ...mergedEntries.map((x: LaserGanttData) => ({
          value: [index, x.start_timestamp * 1000, x.end_timestamp * 1000, x.duration],
          itemStyle: {
            color: getStateColour(x.state),
          },
          state: x.state,
        })),
      );
    }

    return {
      tooltip: {
        confine: true,
        formatter(params: any) {
          const startTime = moment(params.value[1]);
          const endTime = moment(params.value[2]);
          const dateStr = startTime.format('YYYY-MM-DD');
          const duration = formatDuration(params.value[3]);
          return (
            `<p style="color:rgba(74,74,74, 0.5)"> ${dateStr}</p>` +
            `<p>${params.marker + params.name}</p>` +
            `<p>${startTime.format('HH:mm:ss')} - ${endTime.format('HH:mm:ss')}</p>` +
            `${duration}`
          );
        },
      },
      dataZoom: [
        {
          type: 'slider',
          filterMode: 'weakFilter',
          showDataShadow: false,
          bottom: 32,
          labelFormatter: '',
        },
        {
          type: 'inside',
          filterMode: 'weakFilter',
        },
      ],
      grid: {
        top: 8,
        bottom: 96,
        containLabel: true,
        right: 16,
        left: 16,
      },
      xAxis: {
        type: 'time',
      },
      yAxis: {
        data: devices.map((dev: Device) => dev.name),
      },
      legend: {
        show: true,
        bottom: 64,
      },
      series: INTRADAY_GANTT_STATES.map((state) => ({
        type: 'custom' as const,
        renderItem: this.renderItem,
        itemStyle: {
          opacity: 0.8,
          color: getStateColour(state),
        },
        encode: {
          x: [1, 2],
          y: 0,
        },
        animation: false,
        name: i18n.t(`report.${state}`).toString(),
        data: seriesData.filter((x) => x.state === state),
      })).filter((series) => !isEmpty(series.data)),
    };
  }

  override getMockData(): LaserGanttData[] | null {
    return mockGanttData();
  }

  private readonly renderItem: CustomSeriesRenderItem = (
    params: any,
    api: CustomSeriesRenderItemAPI,
  ) => {
    const categoryIndex = api.value(0);
    const start = api.coord([api.value(1), categoryIndex]);
    const end = api.coord([api.value(2), categoryIndex]);
    const height = (api.size!([0, 1]) as number[])[1] * 0.6;

    return {
      type: 'rect',
      transition: ['shape'],
      shape: graphic.clipRectByRect(
        {
          x: start[0],
          y: start[1] - height / 2,
          width: end[0] - start[0],
          height,
        },
        {
          x: params.coordSys.x,
          y: params.coordSys.y,
          width: params.coordSys.width,
          height: params.coordSys.height,
        },
      ),
      style: api.style(),
    };
  };
}
