
import { Component, Prop, Vue } from 'vue-property-decorator';
import { User, UserAuthenticationType } from '@/models/user';
import { UserType } from '@/models/userType';
import { Customer } from '@/models/tenant';
import { usersService } from '@/services/users.service';
import { customersService } from '@/services/tenants.service';
import PasswordResetModal from '@/components/users/PasswordResetModal.vue';
import { hasCurrentUserPermission } from '@/utils/permissionUtils';
import TableActionButton from '@/components/tableWithActions/TableActionButton.vue';
import TableWithActions from '@/components/tableWithActions/TableWithActions.vue';
import CustomerUserWizard from '@/components/users/wizards/CustomerUserWizard.vue';
import { dialogService } from '@/library-services/dialog.service';
import { modalService } from '@/library-services/modal.service';

@Component({
  components: {
    TableWithActions,
    TableActionButton,
  },
})
export default class CustomerUsers extends Vue {
  @Prop({ required: true, default: Customer.GetEmpty() })
  private customer!: Customer;

  private users: User[] = [];

  private isLoading = false;

  private async created() {
    await this.getUsers();
  }

  private async getUsers() {
    this.isLoading = true;
    this.users = await customersService
      .getUsers(this.customer.id)
      .finally(() => (this.isLoading = false));
  }

  private openResetPasswordForm(user: User) {
    modalService.open({
      parent: this,
      component: PasswordResetModal,
      props: {
        id: user.id,
        email: user.email,
      },
    });
  }

  private async onFormModalClosed() {
    if (this.canCreateOrEditCustomerUsers) {
      await this.getUsers();
    }
  }

  private get canCreateOrEditCustomerUsers(): boolean {
    return this.canCreateCustomerUsers || this.canEditCustomerUsers;
  }

  private confirmDelete(id: number, name: string) {
    dialogService.confirmWithPromise({
      title: this.$i18n.t('dialog.confirm_delete_title').toString(),
      message: this.$i18n
        .t('confirmation.message', {
          action: this.$i18n.t('action.delete').toString().toLowerCase(),
          entity: this.$i18n.t('user.name').toString().toLowerCase(),
          name,
        })
        .toString(),
      confirmText: this.$i18n.t('dialog.confirm_delete').toString(),
      cancelText: this.$i18n.t(`dialog.confirm_cancel`).toString(),
      type: 'is-danger',
      onConfirm: async () => await this.deleteUser(id),
    });
  }

  private async deleteUser(id: number) {
    await usersService.delete(id).then(() => {
      this.users = this.users.filter((x) => x.id !== id);
    });
  }

  private get currentUser(): User {
    return usersService.store.current();
  }

  private get canEditCustomerUsers(): boolean {
    return hasCurrentUserPermission(UserType.CustomerAdmin);
  }

  private get canCreateCustomerUsers(): boolean {
    return hasCurrentUserPermission(UserType.CustomerAdmin);
  }

  private get canResetPasswords(): boolean {
    return hasCurrentUserPermission(UserType.SubsidiaryAdmin);
  }

  private canResetPasswordOn(user: User): boolean {
    return (
      (this.canResetPasswords || user.id === this.currentUser.id) &&
      user.authenticationType === UserAuthenticationType.Local
    );
  }

  private canEdit(editingUser: User): boolean {
    if (this.currentUser.equals(editingUser)) {
      return true;
    }

    if (!this.canEditCustomerUsers) {
      return false;
    }

    if (this.currentUser.isCustomerAdmin && editingUser.isCustomerAdmin) {
      return false;
    }

    return true;
  }

  private openCustomerWizard(user: User | null = null) {
    modalService.open({
      parent: this,
      component: CustomerUserWizard,
      canCancel: ['x'],
      props: {
        user,
        customer: this.customer,
      },
      events: {
        save: () => this.onFormModalClosed(),
      },
    });
  }
}
