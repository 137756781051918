
import { Component, Prop, Vue } from 'vue-property-decorator';
import { alertsService } from '@/services/alerts.service';
import { Customer } from '@/models/tenant';
import { Alert } from '@/models/alert';
import AlertCreationModal from '@/components/alerts/AlertCreationModal.vue';
import AlertEditionModal from '@/components/alerts/AlertEditionModal.vue';
import { AlertOperator } from '@/models/enums/AlertsEnums';
import TableActionButton from '@/components/tableWithActions/TableActionButton.vue';
import TableWithActions from '@/components/tableWithActions/TableWithActions.vue';
import { dialogService } from '@/library-services/dialog.service';
import { modalService } from '@/library-services/modal.service';
import i18n from '@/i18n';

@Component({
  components: {
    TableWithActions,
    TableActionButton,
  },
})
export default class CustomerAlerts extends Vue {
  @Prop({ required: true })
  private customer!: Customer;

  private alerts: Alert[] = [];

  private isLoading = false;

  private async created() {
    await this.getAlerts();
  }

  private async getAlerts() {
    this.isLoading = true;
    alertsService
      .getWorkCenterConsoleAlerts(this.customer.id)
      .then((response) => (this.alerts = response))
      .finally(() => (this.isLoading = false));
  }

  private confirmDelete(alert: Alert): void {
    dialogService.confirmWithPromise({
      title: this.$i18n.t('dialog.confirm_title').toString(),
      message:
        this.$i18n.t('action.delete').toString() +
        ` <b>${i18n.t(`alert.metric.${alert.metric}`)} ${i18n.t(
          `alert_operators.${AlertOperator[alert.operator]}`,
        )} ${alert.threshold} ${alert.deviceName ?? this.$i18n.t(`alert.all_devices`)}</b>?`,
      confirmText: this.$i18n.t('dialog.confirm_delete').toString(),
      cancelText: this.$i18n.t(`dialog.confirm_cancel`).toString(),
      type: 'is-danger',
      onConfirm: async () => await this.deleteAlert(alert.id!),
    });
  }

  private async createAlert() {
    modalService.open({
      parent: this,
      component: AlertCreationModal,
      events: {
        save: () => this.getAlerts(),
      },
      props: {
        customer: this.customer,
      },
    });
  }

  private async updateAlert(alert: Alert) {
    if (!!alert.id) {
      modalService.open({
        parent: this,
        component: AlertEditionModal,
        props: {
          alert,
          tenant: this.customer,
        },
        events: {
          save: () => this.getAlerts(),
        },
      });
    }
  }

  private async deleteAlert(alertId: number) {
    await alertsService.delete(alertId).then(() => {
      this.alerts = this.alerts.filter((alert) => alert.id !== alertId);
    });
  }

  private getDeviceName(alert: Alert) {
    return alert.deviceName ?? this.$i18n.t(`alert.all_devices`);
  }

  private getMetric(alert: Alert) {
    return this.$i18n.t(`alert.metric.${alert.metric}`);
  }

  private getOperator(alert: Alert) {
    return this.$i18n.t(`alert_operators.${AlertOperator[alert.operator]}`);
  }

  private getAction(alert: Alert) {
    return this.$i18n.t(`alert.action.${alert.action}`);
  }
}
