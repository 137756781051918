
import { Customer } from '@/models/tenant';
import { Routes } from '@/router/routes';
import { customersService } from '@/services/tenants.service';
import { Component, Vue } from 'vue-property-decorator';
import CustomerProperties from './CustomerProperties.vue';
import CustomerUsers from './CustomerUsers.vue';
import Devices from '@/views/Devices.vue';
import CustomerShiftModels from '@/views/customers/CustomerShiftModels.vue';
import { User } from '@/models/user';
import { UserType } from '@/models/userType';
import CustomerBendPartCategoryConfig from '@/views/customers/CustomerBendPartCategoryConfig.vue';
import CustomerCutGasTypeConfig from '@/views/customers/CustomerCutGasTypeConfig.vue';
import CustomerAlerts from './CustomerAlerts.vue';
import CustomerMaterialGroups from '@/views/customers/CustomerMaterialGroups.vue';
import CustomerTarget from './CustomerTarget.vue';
import CustomerLocations from './CustomerLocations.vue';
import { ConsoleEnum } from '@/models/enums/ConsoleEnum';
import CustomerEmailReport from './CustomerEmailReport.vue';
import { usersService } from '@/services/users.service';
import { Logger } from '@/utils/logger';
import { hasCurrentUserPermission } from '@/utils/permissionUtils';
import { toastService } from '@/library-services/toast.service';

@Component({
  computed: {
    Tab: () => Tab,
  },
  components: {
    CustomerProperties,
    CustomerUsers,
    'customer-devices': Devices,
    CustomerShiftModels,
    CustomerBendPartCategoryConfig,
    CustomerCutGasTypeConfig,
    CustomerMaterialGroups,
    CustomerAlerts,
    CustomerTarget,
    CustomerLocations,
    CustomerEmailReport,
  },
})
export default class CustomerDetail extends Vue {
  private customer = Customer.GetEmpty();
  private selectedTab = Tab.Properties;

  private async created() {
    await this.redirectToCustomersPageIfRouteParameterIsMissing('id');

    this.customer = await this.fetchCustomer(+this.$route.params.id);
  }

  private async fetchCustomer(id: number): Promise<Customer> {
    this.$spinner.showSpinner();
    try {
      return await customersService.getById(id);
    } finally {
      this.$spinner.removeSpinner();
    }
  }

  private onCustomerUpdate(updatedCustomer: Customer) {
    this.customer = updatedCustomer;
  }

  private get canAccessShifts(): boolean {
    return hasCurrentUserPermission(UserType.CustomerAdmin) && this.wccEnabled;
  }

  private get canAccessCategoryConfiguration(): boolean {
    return hasCurrentUserPermission(UserType.CustomerAdmin) && this.wccEnabled;
  }

  private get canAccessCutConfiguration(): boolean {
    return hasCurrentUserPermission(UserType.CustomerAdmin) && this.wccEnabled;
  }

  private get canAccessMaterialGroups(): boolean {
    return hasCurrentUserPermission(UserType.CustomerAdmin) && this.wccEnabled;
  }

  private get canAccessAlerts(): boolean {
    return hasCurrentUserPermission(UserType.CustomerAdmin) && this.wccEnabled;
  }

  private get canAccessTargets(): boolean {
    return hasCurrentUserPermission(UserType.CustomerAdmin) && (this.wccEnabled || this.bcEnabled);
  }

  private get canAccessEmailReports(): boolean {
    return hasCurrentUserPermission(UserType.CustomerAdmin) && this.wccEnabled;
  }

  private get wccEnabled() {
    return !!this.user?.consoles.includes(ConsoleEnum.WCC) && this.customer.hasDatahub;
  }

  private get bcEnabled() {
    return (
      this.customer.hasSmbs &&
      (this.user?.consoles.includes(ConsoleEnum.BC) || this.user?.isGlobalAdmin)
    );
  }

  private get user(): User {
    return usersService.store.current();
  }

  private async redirectToCustomersPageIfRouteParameterIsMissing(parameter: string) {
    if (!this.$route.params[parameter]) {
      Logger.error(`Missing '${parameter}' route parameter. Redirecting to Tenants...`);
      toastService.error(`Missing '${parameter}' route parameter`);
      await this.$router.push({ name: Routes.Tenants });
      throw Error(`Missing '${parameter}' route parameter. Redirecting to Tenants...`);
    }
  }
}

enum Tab {
  Properties,
  Devices,
  Users,
  Locations,
  ShiftModels,
  CutGas,
  BendPartCategory,
  MaterialGroups,
  Alerts,
  Targets,
  EmailReports,
}
