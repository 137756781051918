// --------------------------------------------------------------------------------
// <copyright file="i18n.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { isNil } from '@/utils/misc';
import { getCurrentInstance } from 'vue';

export function useI18n() {
  const instance = getCurrentInstance();

  if (isNil(instance)) {
    throw new Error('useI18n must be called within a setup function');
  }

  return instance.proxy.$i18n;
}
