
import { Component, Vue } from 'vue-property-decorator';
import { Customer } from '@/models/tenant';
import { customersService } from '@/services/tenants.service';
import { Routes } from '@/router/routes';
import CustomerCreationModal from '../../components/customers/CustomerCreationModal.vue';
import { User } from '@/models/user';
import { UserType } from '@/models/userType';
import { hasCurrentUserPermission } from '@/utils/permissionUtils';
import TableActionButton from '@/components/tableWithActions/TableActionButton.vue';
import TableWithActions from '@/components/tableWithActions/TableWithActions.vue';
import { usersService } from '@/services/users.service';
import { formatDateWithTime } from '../../utils/table';
import { modalService } from '@/library-services/modal.service';
import { dialogService } from '@/library-services/dialog.service';

@Component({
  methods: { formatDateWithTime },
  components: {
    TableActionButton,
    TableWithActions,
  },
})
export default class Customers extends Vue {
  private customers: Customer[] = [];

  private isLoading = false;

  private async mounted() {
    await this.getCustomers();
  }

  private confirmDelete(customer: Customer): void {
    dialogService.confirmWithPromise({
      title: this.$i18n.t('dialog.confirm_delete_title').toString(),
      message: this.$i18n
        .t('confirmation.message', {
          action: this.$i18n.t('action.delete').toString().toLowerCase(),
          entity: this.$i18n.t('tenant.name').toString().toLowerCase(),
          name: customer.name,
        })
        .toString(),
      confirmText: this.$i18n.t('dialog.confirm_delete').toString(),
      cancelText: this.$i18n.t(`dialog.confirm_cancel`).toString(),
      type: 'is-danger',
      onConfirm: async () => await this.deleteCustomer(customer.id),
    });
  }

  private async editCustomer(customer: Customer) {
    await this.$router.push({
      name: Routes.TenantDetail,
      params: { id: customer.id.toString() },
    });
  }

  private async modalClose() {
    await this.getCustomers();
  }

  private async getCustomers() {
    this.isLoading = true;
    this.customers = await customersService.get().finally(() => (this.isLoading = false));
  }

  private createCustomer() {
    modalService.open({
      parent: this,
      component: CustomerCreationModal,
      events: {
        save: () => this.getCustomers(),
      },
    });
  }

  private async deleteCustomer(id: number) {
    await customersService.delete(id).then(() => {
      this.customers = this.customers.filter((x) => x.id !== id);
    });
  }

  private get canEditCustomers(): boolean {
    return hasCurrentUserPermission(UserType.SubsidiaryAdmin);
  }

  private get currentUser(): User {
    return usersService.store.current();
  }

  private filterCustomerIdDh(customer: Customer, filter: string) {
    return customer.tenantIdDh.toString().includes(filter.toLowerCase());
  }

  private orderColumnEllipsisTitle(row: any, column: any) {
    if (column.rootClasses.indexOf('order-column') !== -1) {
      return {
        title: row[column.field],
      };
    }
  }
}
