// --------------------------------------------------------------------------------
// <copyright file="pieChartKpiWidgetGenerator.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { abbreviateNumber, formatPercentage } from '@/utils/number';
import { EChartsOption } from 'echarts';
import { KpiWidgetData } from '../chartsData';
import { GeneratorParams } from '../generatorParams';
import { AbstractKpiWidgetGenerator } from './abstractKpiWidgetGenerator';
import { devicesService } from '@/services/devices.service';
import { PieDataItemOption } from 'echarts/types/src/chart/pie/PieSeries';

export abstract class PieChartKpiWidgetGenerator
  extends AbstractKpiWidgetGenerator<KpiWidgetData>
{
  override updateOptions(
    data: KpiWidgetData[],
    parameters: GeneratorParams,
    prevOptions?: EChartsOption,
  ): EChartsOption {
    return {
      tooltip: {
        formatter: (params: any) => {
          const { name, value, percent } = params;
          return `
            ${name}: <b>${this.formatWithUnit(abbreviateNumber(value, 2))}</b>
            (${formatPercentage(percent)})
          `;
        },
      },
      series: [
        {
          type: 'pie',
          radius: ['55%', '85%'],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: 'center',
          },
          labelLine: {
            show: false,
          },
          data: this.getSeriesData(data),
        },
      ],
      graphic: [
        {
          type: 'group',
          top: 'center',
          left: 'center',
          children: [
            {
              type: 'text',
              bottom: '80%',
              left: 'center',
              style: {
                text: this.formatWithUnit(abbreviateNumber(this.getMaxValue(data))),
                font: '20px Arial',
              },
            },
            {
              type: 'text',
              top: '80%',
              left: 'center',
              style: {
                text: 'Top 1',
                fill: this.mainColor,
                font: 'bold 16px Inter',
              },
            },
          ],
        },
      ],
    };
  }

  private getMaxValue(data: KpiWidgetData[]): number {
    return Math.max(...data.map((dataItem) => dataItem.currentValue));
  }

  protected override getSeriesData(data: KpiWidgetData[]): PieDataItemOption[] {
    const palette = this.generatePalette(data.length);

    return data
      .sort((a, b) => a.currentValue - b.currentValue)
      .map((dataItem, index) =>
        ({
          name: devicesService.store.findByStringId(dataItem.deviceStringId)!.name,
          value: dataItem.currentValue,
          itemStyle: {
            color: palette[index],
          },
        }),
      );
  }
}
