
import { Component, Prop, Vue } from 'vue-property-decorator';
import MaterialGroupCreationModal from '@/components/materialGroups/MaterialGroupCreationModal.vue';
import MaterialGroupModal from '@/components/materialGroups/MaterialGroupModal.vue';
import { materialsService } from '@/services/materials.service';
import { MaterialGroup } from '@/models/materialGroup';
import { Customer } from '@/models/tenant';
import { materialGroupsService } from '@/services/materialGroups.service';
import { Material } from '@/models/material';
import MaterialGroupAssignmentModal from '@/components/materialGroups/MaterialGroupAssignmentModal.vue';
import TableActionButton from '@/components/tableWithActions/TableActionButton.vue';
import TableWithActions from '@/components/tableWithActions/TableWithActions.vue';
import { modalService } from '@/library-services/modal.service';
import { dialogService } from '@/library-services/dialog.service';

@Component({
  components: {
    TableActionButton,
    TableWithActions,
  },
})
export default class CustomerMaterialGroups extends Vue {
  @Prop({ required: true, default: MaterialGroup.GetEmpty() })
  private customer!: Customer;

  private materialGroups: MaterialGroup[] = [];
  private materials: Material[] = [];

  private isLoadingMaterials = false;
  private isLoadingMaterialGroups = false;

  private created() {
    this.refreshData();
  }

  private async refreshData() {
    await this.getMaterialGroups();
    await this.getMaterials();
  }

  private async startAssignMaterialToGroup(material: Material) {
    modalService.open({
      parent: this,
      component: MaterialGroupAssignmentModal,
      props: {
        materialGroups: this.knownMaterialGroups,
        material,
      },
      events: {
        save: () => this.refreshData(),
      },
    });
  }

  private confirmDelete(id: number, name: string): void {
    dialogService.confirmWithPromise({
      title: this.$i18n.t('dialog.confirm_delete_title').toString(),
      message: this.$i18n
        .t('confirmation.message', {
          action: this.$i18n.t('action.delete').toString().toLowerCase(),
          entity: this.$i18n.t('material_group.name').toString().toLowerCase(),
          name,
        })
        .toString(),
      confirmText: this.$i18n.t('dialog.confirm_delete').toString(),
      cancelText: this.$i18n.t(`dialog.confirm_cancel`).toString(),
      type: 'is-danger',
      onConfirm: async () => await this.deleteMaterialGroup(id),
    });
  }

  private get unassignedMaterials(): Material[] {
    const unassignedGroupsIds = this.materialGroups.filter((g) => g.isUnknown).map((g) => g.id);
    return this.materials.filter((m) => unassignedGroupsIds.includes(m.materialGroupId));
  }

  private get knownMaterialGroups(): MaterialGroup[] {
    return this.materialGroups.filter((group) => !group.isUnknown);
  }

  private createMaterialGroup() {
    modalService.open({
      parent: this,
      component: MaterialGroupCreationModal,
      props: {
        customer: this.customer,
      },
      events: {
        save: async () => this.getMaterialGroups(),
      },
    });
  }

  private async deleteMaterialGroup(id: number) {
    await materialGroupsService.delete(id).then(() => {
      this.materialGroups = this.materialGroups.filter((x) => x.id !== id);
    });
  }

  private async getMaterialGroups() {
    this.isLoadingMaterialGroups = true;
    this.materialGroups = await materialGroupsService
      .get(this.customer.id)
      .finally(() => (this.isLoadingMaterialGroups = false));
  }

  private async getMaterials() {
    this.isLoadingMaterials = true;
    this.materials = await materialsService
      .get(this.customer.id)
      .finally(() => (this.isLoadingMaterials = false));
  }

  private manageMaterialGroup(materialGroupId: number) {
    modalService.open({
      parent: this,
      component: MaterialGroupModal,
      props: {
        customer: this.customer,
        materialGroupId,
        materialGroups: this.materialGroups,
      },
      events: {
        close: async () => await this.getMaterials(),
      },
    });
  }
}
