// --------------------------------------------------------------------------------
// <copyright file="numberOfBendsWidgetGenerator.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { metricsService } from '@/services/metrics.service';
import { FilterTimeAxisSpanEnum } from '../enums/FilterTimeAxisSpanEnum';
import { FilterTimeSpanEnum } from '../enums/FilterTimeSpanEnum';
import { PieChartKpiWidgetGenerator } from './abstract/pieChartKpiWidgetGenerator';
import { KpiWidgetData } from './chartsData';
import { ProcedureName } from './abstract/chartGenerator';
import { BEND_SUMMARY_BASE_COLOR } from '@/utils/color';

/**
 * Chart generator for the 'Number of bends' widget.
 *
 * This widget is placed in Work center console > Bend > Summary.
 *
 * Returns Echarts options to draw a pie chart with a segment for each device
 * representing the percentage of bends for the specified time period.
 *
 * The number of bends of device with more bends is shown in the center.
 */
export class NumberOfBendsWidgetGenerator extends PieChartKpiWidgetGenerator {
  constructor(procedure: ProcedureName, customerIdDh: number) {
    const mainColor = BEND_SUMMARY_BASE_COLOR;
    super(procedure, customerIdDh, mainColor);
  }

  override async getData(
    selectedDevices: string[],
    selectedShifts: number[],
    timeSpan: FilterTimeSpanEnum | [string, string],
    timeAxisSpan?: FilterTimeAxisSpanEnum,
    params?: { [key: string]: any },
    customerNo?: string,
  ): Promise<KpiWidgetData[] | null> {
    return await metricsService.getBendingDevicesMetrics<KpiWidgetData[]>(
      this.procedure,
      {
        customerIdDh: this.tenantIdDh,
        deviceStringIds: selectedDevices,
        shiftIds: selectedShifts,
        timeSpan: timeSpan as FilterTimeSpanEnum,
      },
      this.controller,
    );
  }
}
