// --------------------------------------------------------------------------------
// <copyright file="measurement.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { Measurement } from '@/models/measurement';
import { mastersService } from '@/services/masters.service';
import { customersService } from '@/services/tenants.service';
import { Measurements } from '@/models/enums/Measurement';

export function weightUnit(): string {
  const measurement = getCustomerMeasurement();

  switch (measurement?.name) {
    case 'Metric':
      return 'kg';
    case 'Imperial':
      return 'lb';
    default:
      return '';
  }
}

export function lengthUnit(): string {
  const measurement = getCustomerMeasurement();

  switch (measurement?.name) {
    case 'Metric':
      return 'mm';
    case 'Imperial':
      return 'in';
    default:
      return '';
  }
}

function getCustomerMeasurement(): Measurement | undefined {
  const customerMeasurementId = getCustomerMeasurementId();
  return getAvailableMeasurements()?.find((measurement) => customerMeasurementId === measurement.id);
}

function getAvailableMeasurements(): Measurement[] {
  return mastersService.store.getMeasurementUnits();
}

export function getCustomerMeasurementId(): Measurements | null {
  return customersService.store.current()?.measurement ?? null;
}

const KILOGRAMS_TO_POUNDS_CONVERSION_FACTOR = 2.20462262185;

/**
 * Returns the value in kilograms converted to the unit defined by the
 * customer.
 */
export function kilogramsToCustomerUnit(valueKilograms: number): number {
  return weightUnit() === "kg"
    ? valueKilograms
    : kilogramsToPounds(valueKilograms);
}

/** Returns the value in kilograms converted to pounds. */
function kilogramsToPounds(valueKilograms: number) {
  return valueKilograms * KILOGRAMS_TO_POUNDS_CONVERSION_FACTOR;
}

export function getUnitTransform(convertToLbs: boolean): (x: number) => number {
  return convertToLbs
    ? kilogramsToPounds
    : (x: number) => x;
}

const MILLIMETERS_TO_INCHES_CONVERSION_FACTOR = 25.4;

/** Returns the value in millimeters converted to inches. */
// Public for use on unit tests
export function millimetersToInches(valueMillimeters: number) {
  return valueMillimeters / MILLIMETERS_TO_INCHES_CONVERSION_FACTOR;
}

/**
 * Returns the value in millimeters converted to the unit defined by the
 * customer.
 */
export function millimetersToCustomerUnit(valueMillimeters: number): number {
  return lengthUnit() === "mm"
    ? valueMillimeters
    : millimetersToInches(valueMillimeters);
}
