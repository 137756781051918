// --------------------------------------------------------------------------------
// <copyright file="customerReport.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

export class CustomerReport {
  constructor(
    public id: number,
    public hasDailyReport: boolean,
    public hasWeeklyReport: boolean,
    public hasMonthlyReport: boolean,
    public hasQuarterlyReport: boolean,
    public hasAnnualReport: boolean,
    public reportUserEmails?: string[],
  ) {}
}
