
import { Component, Vue, Watch } from 'vue-property-decorator';
import { areArraysEqual } from '@/utils/array';
import CustomTableFilters from '@/components/tableWithActions/CustomTableFilters.vue';
import { isEmpty } from '@/utils/misc';
import { AlertFilterValues } from '@/views/careConsole/commons/model';
import { FilterTimeAxisSpanEnum } from '@/models/enums/FilterTimeAxisSpanEnum';
import { getEnumValuesFromQueryParameterOrDefault } from '@/utils/url';
import DateGroupingSelect from '@/components/DateGroupingSelect.vue';

/**
 * Filters form for the alert configurations table.
 */
@Component({
  components: {
    CustomTableFilters,
    DateGroupingSelect,
  },
})
export default class AlertFilters extends Vue {
  private static readonly defaultFilterValues: AlertFilterValues = Object.freeze({
    tenantName: [],
    deviceName: [],
    metric: [],
    operator: [],
    threshold: [],
    dateGrouping: [],
    eventSources: [],
    eventCodes: [],
    variable: [],
  });

  private filters = structuredClone(AlertFilters.defaultFilterValues);
  private isUpdatingUrlQueryParametersFromFilters = false;

  private mounted() {
    this.filters = structuredClone(AlertFilters.defaultFilterValues);
    this.updateFiltersFromUrlQueryParameters();
  }

  clearAllFilters() {
    this.filters = structuredClone(AlertFilters.defaultFilterValues);
    this.onFiltersApplied();
  }

  private onFiltersApplied() {
    this.updateUrlQueryParametersFromFilters();
    this.emitFilters();
  }

  /**
   * Updates the URL's query parameters with the current values of the table
   * filters.
   *
   * Example:
   *   filter = { tenantName = ['name1', 'name2'] }
   *   URL query parameters: ?tenantName=name1&tenantName=name2
   */
  private async updateUrlQueryParametersFromFilters() {
    const queryParameters = (Object.entries(this.filters) as Array<[string, any[]]>)
      .filter(([_, value]) => !isEmpty(value))
      .map(([key, arrayValue]) => {
        let stringValues = arrayValue;

        if (typeof arrayValue[0] === 'number') {
          // DateGrouping filter
          stringValues = arrayValue.map((valueItem: number) => FilterTimeAxisSpanEnum[valueItem]);
        }

        return { [key]: stringValues };
      })
      .reduce((resultObject, currentValue) => ({ ...resultObject, ...currentValue }), {});

    this.isUpdatingUrlQueryParametersFromFilters = true;
    await this.$router.replace({ query: queryParameters });
    this.isUpdatingUrlQueryParametersFromFilters = false;
  }

  /**
   * Updates the filter values with the values from the URL's query parameters.
   *
   * For each query parameters the value of the matching filter is updated by
   * deserializing the query parameter values. Invalid values are ignored.
   *
   * Example:
   *   URL query parameters: ?dateGrouping=Week&tenantName=InvalidStatus
   *   filter = { dateGrouping = [FilterTimeAxisSpanEnum.Week] }
   */
  @Watch('$route.query')
  // public for testing
  updateFiltersFromUrlQueryParameters() {
    if (this.isUpdatingUrlQueryParametersFromFilters) {
      // When the filters change, the URL is updated, so no need to update the
      // filters again
      return;
    }

    this.filters = structuredClone(AlertFilters.defaultFilterValues);

    (Object.entries(this.$route.query ?? {}) as [keyof AlertFilterValues, any][]).forEach(
      ([key, value]) => {
        if (TEXT_LIST_FILTERS.includes(key)) {
          this.filters[key] = Array.isArray(value) ? value : ([value] as any);
        } else if (key === 'dateGrouping') {
          this.filters.dateGrouping = this.getDateGroupingFilterValueFromQueryParameter(value);
        }
      },
    );

    if (this.areApplying) {
      // Only emit if the URL contains filters
      this.emitFilters();
    }
  }

  private get areApplying(): boolean {
    return this.areTextListFiltersApplying || !isEmpty(this.filters.dateGrouping);
  }

  private get areTextListFiltersApplying(): boolean {
    return TEXT_LIST_FILTERS.some(
      (textListFilterKey) =>
        !areArraysEqual(
          this.filters[textListFilterKey as keyof AlertFilterValues] as string[],
          AlertFilters.defaultFilterValues[
            textListFilterKey as keyof AlertFilterValues
          ] as string[],
        ),
    );
  }

  private getDateGroupingFilterValueFromQueryParameter(
    queryParameterValue: string | Array<string | null>,
  ): FilterTimeAxisSpanEnum[] {
    return getEnumValuesFromQueryParameterOrDefault(
      queryParameterValue,
      FilterTimeAxisSpanEnum,
      AlertFilters.defaultFilterValues.dateGrouping,
    );
  }

  private emitFilters() {
    this.$emit('filters-change', this.filters, this.areApplying);
  }
}

const TEXT_LIST_FILTERS = [
  'tenantName',
  'deviceName',
  'metric',
  'operator',
  'threshold',
  'eventSources',
  'eventCodes',
  'variable',
];
