
import { Component, Prop, Vue } from 'vue-property-decorator';
import { materialGroupsService } from '@/services/materialGroups.service';
import { MaterialGroup } from '@/models/materialGroup';
import { Customer } from '@/models/tenant';
import { materialsService } from '@/services/materials.service';
import TableActionButton from '../tableWithActions/TableActionButton.vue';
import TableWithActions from '../tableWithActions/TableWithActions.vue';
import { dialogService } from '@/library-services/dialog.service';

@Component({
  components: {
    TableActionButton,
    TableWithActions,
  },
})
export default class MaterialGroupModal extends Vue {
  @Prop({ required: true, default: MaterialGroup.GetEmpty() })
  public customer!: Customer;
  @Prop({ required: false, default: null })
  public materialGroupId!: number;
  @Prop({ required: true, default: [] })
  public materialGroups!: MaterialGroup[];

  @Prop({ default: false })
  public disabled!: boolean;

  public materialGroup: MaterialGroup = MaterialGroup.GetEmpty();

  private isLoading = false;

  public mounted() {
    this.refreshData();
  }

  public closeModal() {
    this.$emit('close');
  }

  public async refreshData() {
    this.isLoading = true;
    materialGroupsService
      .getDetails(this.materialGroupId)
      .then((data: MaterialGroup) => {
        this.materialGroup = data;
      })
      .finally(() => (this.isLoading = false));
  }

  public confirmUnassign(id: number, name: string): void {
    dialogService.confirmWithPromise({
      title: this.$i18n.t('dialog.confirm_title').toString(),
      message: this.$i18n.t('material.dialog.unassign').toString() + ` <b>${name}</b>?`,
      confirmText: this.$i18n.t('dialog.confirm_unassign').toString(),
      cancelText: this.$i18n.t(`dialog.confirm_cancel`).toString(),
      type: 'is-danger',
      onConfirm: async () => await this.unassignMaterial(id),
    });
  }

  private async unassignMaterial(id: number) {
    const material = this.materialGroup.materials.find((m) => m.id === id)!;
    const materialGroup = this.materialGroups.find((g) => g.isUnknown)!;

    material.materialGroupId = materialGroup.id;
    await materialsService.update(material).then(() => {
      this.materialGroup.materials = this.materialGroup.materials?.filter((x) => x.id !== id);
    });
  }
}
