
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import WidgetContainer from '@/components/Charts/widgetContainer/WidgetContainer.vue';
import { FilterTimeSpanEnum } from '@/models/enums/FilterTimeSpanEnum';
import { FilterTimeAxisSpanEnum } from '@/models/enums/FilterTimeAxisSpanEnum';
import moment from 'moment';
import DateGroupingFilter from '@/components/DateGroupingFilter.vue';
import TimeSpanFilter from '@/components/TimeSpanFilter.vue';
import ShiftFilter from '@/components/ShiftFilter.vue';
import { MachineType } from '@/models/enums/MachineType';
import ClickMenu from '@/components/menu/ClickMenu.vue';
import { Device } from '@/models/device';
import WidgetDefinition from '@/models/Charts/widgetDefinition';
import { WidgetEnum } from '@/models/enums/WidgetEnum';
import { dateRangeToDateStrings } from '@/utils/dates';
import { devicesService } from '@/services/devices.service';
import { availableDashboardLayouts, DashboardLayout } from '@/models/Charts/dashboardDefinition';
import LayoutGrid from '../dashboard/LayoutGrid.vue';
import { indexedWidgetDefinitions, isEmpty, isNil } from '@/utils/misc';
import MultipleSelectionFiltersHeader from '@/components/MultipleSelectionFiltersHeader.vue';
import { Shift } from '@/models/shift';
import { getActiveShifts } from '@/utils/page-filters';
import { TubesTab } from '@/views/workcenterConsole/TubesTab';
import { Route } from 'vue-router';

/**
 * Page containing with widgets showing information about tubes devices.
 *
 * Props:
 * - selectedDeviceStringId (optional): String id of the device to set as
 *   selected.
 */
@Component({
  computed: {
    TubesTab: () => TubesTab,
    MachineType: () => MachineType,
  },
  components: {
    MultipleSelectionFiltersHeader,
    WidgetContainer,
    ClickMenu,
    DateGroupingFilter,
    LayoutGrid,
    ShiftFilter,
    TimeSpanFilter,
  },
})
export default class Tubes extends Vue {
  /** String id of the device to set as selected. Optional. */
  @Prop()
  private selectedDeviceStringId?: string;

  private activeTab = TubesTab.Overview;
  private widgetDefinitions: WidgetDefinition[] = [];

  private dateRangeSelection: [Date, Date] = [moment().subtract(6, 'days').toDate(), new Date()];
  private selectedDateGrouping = FilterTimeAxisSpanEnum.Day;
  private selectedTimespan = FilterTimeSpanEnum.Day;
  private selectedShiftIds: number[] = [];
  private selectedDeviceIds: string[] = [];

  private gridLayout = availableDashboardLayouts[DashboardLayout.TwoByThree];
  private intradayAvailabilityLayout = availableDashboardLayouts[DashboardLayout.OneByOne];

  @Ref('dateRangePicker')
  private dateRangePicker: any;

  // Updates the active tab when the hash changes by pasting a new URL or editing it
  private beforeRouteUpdate(to: Route, from: Route, next: () => void) {
    // .substring removes initial '#' from hash
    const hash = to.hash?.substring(1);
    if (hash !== TubesTab[this.activeTab]) {
      this.activeTab = TubesTab[hash as keyof typeof TubesTab];
    }
    return next();
  }

  private async created() {
    this.widgetDefinitions = this.createWidgetDefinitions();
    this.selectedDeviceIds = this.selectedDeviceStringId ? [this.selectedDeviceStringId] : [];
    await this.syncActiveTabWithRouteHash();
  }

  @Watch('isTrendsTabActive')
  private changeWidgetDefinitions() {
    this.widgetDefinitions = this.createWidgetDefinitions();
  }

  @Watch('activeDeviceIds')
  @Watch('dateRangeString')
  @Watch('selectedTimespan')
  @Watch('selectedShiftIds')
  @Watch('selectedDateGrouping')
  private updateFilterOnWidgetDefinitions() {
    this.widgetDefinitions = this.widgetDefinitions.map((widgetDefinition) =>
      widgetDefinition.getCopy({
        deviceId: this.activeDeviceIds,
        timeFilter:
          this.isTrendsTabActive &&
          (widgetDefinition as WidgetDefinition).widget !== WidgetEnum.BendingPerformance
            ? this.dateRangeString
            : this.selectedTimespan,
        shifts: this.selectedShiftIds,
        axisSpan: this.selectedDateGrouping,
      }),
    );
  }

  private updateWidgetDefinition(index: number, widgetDefinition: WidgetDefinition) {
    this.$set(this.widgetDefinitions, index, widgetDefinition);
  }

  private updateAggregate(index: number, aggregates: number): void {
    this.$set(this.widgetDefinitions, index, this.widgetDefinitions[index].getCopy({ aggregates }));
  }

  private createWidgetDefinitions(): WidgetDefinition[] {
    const initialTimeFilter = this.isTrendsTabActive ? this.dateRangeString : this.selectedTimespan;

    return indexedWidgetDefinitions([
      new WidgetDefinition(
        this.isTrendsTabActive ? WidgetEnum.TubeAvailabilityHist : WidgetEnum.TubeAvailability,
        this.activeDeviceIds,
        initialTimeFilter,
        this.selectedShiftIds,
        undefined,
        this.selectedDateGrouping,
      ),
      new WidgetDefinition(WidgetEnum.TubeStatus, this.activeDeviceIds, undefined, []),
      new WidgetDefinition(
        this.isTrendsTabActive ? WidgetEnum.TubeOeeHist : WidgetEnum.TubeOee,
        this.activeDeviceIds,
        initialTimeFilter,
        this.selectedShiftIds,
        undefined,
        this.selectedDateGrouping,
      ),
      new WidgetDefinition(
        this.isTrendsTabActive ? WidgetEnum.TubeStarveBlockHist : WidgetEnum.TubeStarveBlock,
        this.activeDeviceIds,
        initialTimeFilter,
        this.selectedShiftIds,
        undefined,
        this.selectedDateGrouping,
      ),
      new WidgetDefinition(
        this.isTrendsTabActive ? WidgetEnum.TubeOutputScrapHist : WidgetEnum.TubeOutputScrap,
        this.activeDeviceIds,
        initialTimeFilter,
        this.selectedShiftIds,
        undefined,
        this.selectedDateGrouping,
      ),
      new WidgetDefinition(
        this.isTrendsTabActive ? WidgetEnum.TubeCuttingTimeHist : WidgetEnum.TubeCuttingTime,
        this.activeDeviceIds,
        initialTimeFilter,
        this.selectedShiftIds,
        undefined,
        this.selectedDateGrouping,
      ),
    ]);
  }

  private get ganttChartWidgetDefinition(): WidgetDefinition {
    return new WidgetDefinition(
      WidgetEnum.TubeStatesGantt,
      this.activeDeviceIds,
      undefined,
      this.selectedShiftIds,
    );
  }

  private async syncActiveTabWithRouteHash() {
    if (isEmpty(this.$route.hash)) {
      await this.updateRouteHashWithActiveTab();
    } else {
      this.activeTab = TubesTab[this.$route.hash.substring(1) as keyof typeof TubesTab];

      if (isNil(this.activeTab)) {
        this.activeTab = TubesTab.Overview;
        await this.updateRouteHashWithActiveTab();
      }
    }
  }

  @Watch('activeTab')
  private async updateRouteHashWithActiveTab() {
    // Avoid 'redundant navigation' error on page reload
    if (this.$route.hash.includes(TubesTab[this.activeTab])) {
      return;
    }

    await this.$router.replace({
      hash: TubesTab[this.activeTab],
    });
  }

  private get activeShifts(): Shift[] {
    return getActiveShifts(this.selectedShiftIds, this.selectedDeviceIds, this.devices);
  }

  private get activeDeviceIds(): string[] {
    return isEmpty(this.selectedDeviceIds) ? this.deviceIds : this.selectedDeviceIds;
  }

  private get deviceIds(): string[] {
    return this.devices.map((device: Device) => device.deviceId);
  }

  private get deviceNames(): string[] {
    return this.devices.map((device: Device) => device.name);
  }

  private get devices(): Device[] {
    return devicesService.store.tubeDevices();
  }

  private get isTrendsTabActive(): boolean {
    return this.activeTab === TubesTab.Trends;
  }

  private get dateRangeString(): [string, string] {
    return dateRangeToDateStrings(this.dateRangeSelection);
  }

  private toggleDateRangePicker() {
    this.dateRangePicker.toggle();
  }

  private get activeDevices(): Device[] {
    return this.devices.filter((device) => this.activeDeviceIds.includes(device.deviceId));
  }

  private get isOverviewTabActive() {
    return this.activeTab === TubesTab.Overview;
  }
}
