
import { Component, Vue } from 'vue-property-decorator';
import { customersService } from '@/services/tenants.service';
import { Customer } from '@/models/tenant';
import TimezonesSelect from '../inputs/TimezonesSelect.vue';
import CurrencyCodesSelect from '../inputs/CurrencyCodesSelect.vue';
import MeasurementsSelect from '../inputs/MeasurementsSelect.vue';
import SubsidiariesSelect from '../inputs/SubsidiariesSelect.vue';
import CustomerSourcesSelect from '../inputs/CustomerSourcesSelect.vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import RequiredFieldsHint from '../forms/RequiredFieldsHint.vue';

@Component({
  components: {
    RequiredFieldsHint,
    TimezonesSelect,
    CurrencyCodesSelect,
    MeasurementsSelect,
    SubsidiariesSelect,
    CustomerSourcesSelect,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class CustomerCreationModal extends Vue {
  private customer = Customer.GetEmpty();
  private isSubmitting = false;

  private get validationRules(): any {
    return {
      name: {
        required: true,
        max: 256,
      },
      subsidiaryId: { required: true },
      email: {
        email: true,
        max: 256,
      },
      foreName: {
        max: 256,
      },
      lastName: {
        max: 256,
      },
      sapNumber: {
        required: true,
        max: 256,
      },
      currencyCode: { required: true },
      measurement: { required: true },
      timeZone: { required: true },
      bystronicOrderNumber: {
        required: this.customer.hasDatahub || this.customer.hasSmbs,
        max: 1024,
      },
      bystronicOrderPerson: {
        required: this.customer.hasDatahub || this.customer.hasSmbs,
        max: 1024,
      },
      customerOrderNumber: {
        max: 1024,
      },
      customerOrderPerson: {
        max: 1024,
      },
    };
  }

  public async onSubmit() {
    this.isSubmitting = true;
    customersService
      .create(this.customer)
      .then(() => {
        this.$emit('save');
        this.$emit('close');
      })
      .finally(() => (this.isSubmitting = false));
  }
}
