import { render, staticRenderFns } from "./LocationCreationModal.vue?vue&type=template&id=536e60ee&scoped=true"
import script from "./LocationCreationModal.vue?vue&type=script&lang=ts"
export * from "./LocationCreationModal.vue?vue&type=script&lang=ts"
import style0 from "./LocationCreationModal.vue?vue&type=style&index=0&id=536e60ee&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "536e60ee",
  null
  
)

export default component.exports