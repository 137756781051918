
import { Component, Prop, Vue } from 'vue-property-decorator';
import { customersService } from '@/services/tenants.service';
import { Customer } from '@/models/tenant';
import { Mode } from '@/models/enums/Mode';
import { CustomerCutConfig } from '@/models/customerCutConfig';

@Component({})
export default class CustomerCutGasTypeConfig extends Vue {
  @Prop({ required: true, default: Customer.GetEmpty() })
  private customer!: Customer;

  private editCustomer = Customer.GetEmpty();
  private mode = Mode.View;

  private isSubmitting = false;

  private async mounted() {
    this.editCustomer = this.customer;
  }

  private startEdit() {
    this.mode = Mode.Edit;
    Vue.nextTick(() => {
      this.checkHtml5Validity();
    });
  }

  private onFormChanged(): void {
    this.editCustomer = Customer.GetCopy(this.editCustomer);
  }

  private get isFormValid() {
    if (this.editCustomer && this.$refs.form) {
      const refForm = this.$refs.form as any;
      if (
        !!this.editCustomer.channel0Name &&
        !!this.editCustomer.channel1Name &&
        !!this.editCustomer.channel2Name &&
        !!this.editCustomer.channel3Name
      ) {
        return refForm.checkValidity();
      }
    }
    return false;
  }

  private async onSubmit() {
    this.isSubmitting = true;
    if (this.isEditMode) {
      await customersService
        .updateCutConfig(
          new CustomerCutConfig(
            this.editCustomer.id,
            this.editCustomer.channel0Name,
            this.editCustomer.channel1Name,
            this.editCustomer.channel2Name,
            this.editCustomer.channel3Name,
          ),
        )
        .then(async () => {
          await customersService.store.update(this.editCustomer);
          this.$emit('customer-updated', this.editCustomer);
        })
        .finally(() => (this.isSubmitting = false));
    }
    this.mode = Mode.View;
  }

  private checkHtml5Validity(): void {
    (this.$refs.gasChannel0_name_input! as any).checkHtml5Validity();
    (this.$refs.gasChannel1_name_input! as any).checkHtml5Validity();
    (this.$refs.gasChannel2_name_input! as any).checkHtml5Validity();
    (this.$refs.gasChannel3_name_input! as any).checkHtml5Validity();
  }

  get isEditMode(): boolean {
    return this.mode === Mode.Edit;
  }

  get isViewMode(): boolean {
    return this.mode === Mode.View;
  }
}
