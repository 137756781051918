// --------------------------------------------------------------------------------
// <copyright file="mockWidgetSelectorData.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { Device } from '../device';
import moment from 'moment';
import {
  BendStatusMetrics,
  CutStatusMetrics,
  TubeStatusMetrics,
} from '@/models/deviceStatusMetrics';
import {
  BEND_FLOW_SPEED,
  BendingAvailabilityData,
  BendingAvailabilityHistoricalData,
  BendingNumberOfBendsTimelineData,
  BendingPerformanceHistoricalData,
  BendingPerformancePartData,
  BendingPerformancePartHistoricalData,
  BendingTimeBetweenBendData,
  BendingTimeBetweenBendHistoricalData,
  BendingTopPartsData,
  BendProductivityFiguresChartData,
  BendProductivityFiguresData,
  BendProductivityFiguresName,
  BusinessOverviewFiguresData,
  Category,
  FactoryOverviewData,
  FinishedPOData,
  FlowSpeedGanttData,
  GanttTableData,
  INTRADAY_GANTT_STATES,
  LaserAvailabilityData,
  LaserAvailabilityHistoricalData,
  LaserCuttingTimeData,
  LaserCuttingTimeHistoricalData,
  LaserGanttData,
  LaserOEEData,
  LaserOeeHistoricalData,
  LaserOutputScrapData,
  LaserOutputScrapHistoricalData,
  LaserProductivityData,
  LaserProductivityHistoricalData,
  LaserStarveAndBlockData,
  LaserStarveAndBlockHistoricalData,
  ManufacturingFiguresData,
  ManufacturingPOFinishedDueDateData,
  ManufacturingPOLStatusDueDateData,
  OpenSalesFunnelData,
  POLStatus,
  POStatus,
  POTreemapData,
  QuoteConversionTimelineData,
  QuotedMaterialsListData,
  QuotesAbcCustomersData,
  QuotesConversionRatesData,
  QuotesCustomerEvolutionData,
  QuotesFiguresData,
  QuotesMaterialsData,
  QuotesSizeData,
  ReleasedPOStatus,
  SalesCustomerGroupsData,
  SalesFiguresData,
  SalesFunnelData,
  SalesPerSalespersonTimelineData,
  SalesTimelineData,
  SalesTopBySalesData,
  UnpaidSalesDistributionData,
  BEND_PRODUCTION_MODES,
  BendSpeedFlowStates,
  BendShiftStatisticsData,
  RankingDataOfBendPart,
  BendPartDataEntry,
} from './chartsData';
import { SMBSMapData } from '../Maps/mapsData';
import { devicesService } from '@/services/devices.service';
import { FilterTimeSpanEnum } from '@/models/enums/FilterTimeSpanEnum';
import { iterable } from '@/utils/array';
import { getMaxSecondsByTimeFilter } from '@/utils/dates';
import { shiftsService } from '@/services/shifts.service';
import { tenantsService } from '@/services/tenants.service';
import { shiftModelsService } from '@/services/shiftModels.service';
import { ShiftModel } from '../shiftModel';
import { Logger } from '@/utils/logger';

export function mockFactoryOverviewData(): FactoryOverviewData[] {
  return devicesService.store.get().map((device) => ({
    deviceid: device.deviceId,
    availability: random(0.9, 1),
    productivity: random(0.8, 0.9),
    timestamp_utc: moment().format('YYYY-MM-DDTHH:mm:ss'),
    state: INTRADAY_GANTT_STATES[Math.floor(random(0, INTRADAY_GANTT_STATES.length))],
  }));
}

export function mockCutStatusData(): CutStatusMetrics {
  return {
    LaserHealth: 100,
    RunningHoursTotal: 19965,
    peak_pwr: 12,
    avg_pwr: 8.64,
    last_state: 'work',
  };
}

export function mockTubeStatusData(): TubeStatusMetrics {
  return {
    RunningHoursTotal: 19965,
    peak_pwr: 12,
    avg_pwr: 8.64,
    last_state: 'work',
  };
}

export function mockBendStatusData(): BendStatusMetrics {
  return {
    health: 93.33,
    parts_produced: 251,
    kg_produced: 227.43,
    running_hours: 8438,
    last_state: 'work',
  };
}

export function mockGanttData(): LaserGanttData[] {
  const ganttData: LaserGanttData[] = [];
  const devices: Device[] = devicesService.store.get();
  const laserDevices = devices.filter((device) => device.deviceId.startsWith('L-'));
  const ganttFirstTimestamp = moment().subtract(32, 'hours').unix();
  const ganttLastTimestamp = moment().unix();
  let stateFirstTimestamp = ganttFirstTimestamp;
  let stateLastTimestamp = ganttFirstTimestamp;

  laserDevices.forEach((device) => {
    while (stateLastTimestamp < ganttLastTimestamp) {
      const duration = random(500, 6000);
      stateLastTimestamp += duration;
      if (stateLastTimestamp > ganttLastTimestamp) {
        continue;
      }
      const deviceStateData: LaserGanttData = {
        deviceid: device.deviceId,
        duration,
        start_timestamp: stateFirstTimestamp,
        end_timestamp: stateLastTimestamp,
        state: INTRADAY_GANTT_STATES[Math.floor(Math.random() * INTRADAY_GANTT_STATES.length)],
      };
      ganttData.push(deviceStateData);
      stateFirstTimestamp = stateLastTimestamp;
    }
    stateFirstTimestamp = ganttFirstTimestamp;
    stateLastTimestamp = ganttFirstTimestamp;
  });
  return ganttData;
}

export function mockFlowSpeedGanttData(): FlowSpeedGanttData[] {
  // In the mock data we do not want it to take the status of processing
  return mockGanttTableData(BEND_FLOW_SPEED.slice(0, -1)).map((entry) => ({
    ...entry,
    flowSpeed: random(0, 100),
  }));
}

export function mockGanttTableData<T>(states: T[]): GanttTableData<T>[] {
  const ganttTableData: GanttTableData<T>[] = [];
  const bendDevices = devicesService.store.bendDevices();

  const ganttFirstTimestamp = moment().subtract(24, 'hours').unix();
  const ganttLastTimestamp = moment().unix();
  let stateFirstTimestamp = ganttFirstTimestamp;
  let stateLastTimestamp = ganttFirstTimestamp;

  bendDevices.forEach((device) => {
    while (stateLastTimestamp < ganttLastTimestamp) {
      const duration = random(500, 6000);
      stateLastTimestamp += duration;
      if (stateLastTimestamp > ganttLastTimestamp) {
        continue;
      }
      const deviceStateData: GanttTableData<T> = {
        deviceid: device.deviceId,
        startTimestamp: stateFirstTimestamp,
        endTimestamp: stateLastTimestamp,
        state: states[Math.floor(Math.random() * states.length)],
      };
      ganttTableData.push(deviceStateData);
      stateFirstTimestamp = stateLastTimestamp;
    }
    stateFirstTimestamp = ganttFirstTimestamp;
    stateLastTimestamp = ganttFirstTimestamp;
  });
  return ganttTableData;
}

export function mockSheetPartsData(): string[] {
  // We choose a base list of invented parts for a Bending machine
  const base = [
    'Punch',
    'Die',
    'Backgauge',
    'Clamping system',
    'Ram',
    'Control panel',
    'Hydraulic system',
    'Foot pedal',
    'Safety guards',
    'Side frames',
    'Pressure gauge',
    'Cooling system',
    'Servo motors',
    'Light curtain',
    'Tool holders',
    'Oil tank',
    'Machine bed',
    'Front support arms',
    'Automatic lubrication system',
    'Electronic controls',
  ];

  // Shuffle the array using the Fisher-Yates algorithm
  for (let i = base.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [base[i], base[j]] = [base[j], base[i]];
  }

  // Generate a random number of elements to return
  const numParts = Math.floor(Math.random() * base.length) + 1;

  return base.slice(0, numParts);
}

/**
 * Based on a predefined total number and the number of partitions (or divisions), we will obtain a array of random numbers.
 * Where the sum of these numbers equals the total and the length of the array equals the number of partitions (or divisions).
 * @total: corresponds to the number of the sum of all partitions. That is, it would correspond to 100 percent of the sample.
 * @parts: corresponds to the number of partitions or divisions of the total.
 * For example, if total=100 and parts=3, this function could return an array like this [50,30,20] or [80,15,5].
 * Because the sum of these partitions is equal to total.
 */
function randomPartition(total: number, parts: number): number[] {
  // Generate an array of ‘parts - 1’ random numbers between 0 and 1
  const randoms = Array.from({ length: parts - 1 }, () => random(0.1, 1));
  // Adds the limits 0 and 1 to the random number array
  randoms.push(0, 1);
  // Sort the array of random numbers including 0 and 1
  randoms.sort();
  // Calculates the differences between consecutive values in the array
  // Multiplies those differences by the total to generate the partitions
  // Use Math.floor to obtain integers in the partitions
  return randoms.slice(1).map((r, i) => Math.floor(total * Math.max(0.15, r - randoms[i])));
}

export function mockLaserAvailabilityData(): LaserAvailabilityData[] {
  return [
    {
      work: 57.75,
      error: 0.64,
      wait: 3.95,
      idle: 3.25,
      stop: 0,
      offline: 4.62,
      isprimary: 1,
    },
    {
      work: 87.38,
      error: 0.07,
      wait: 2.31,
      idle: 2.24,
      stop: 0,
      offline: 0,
      isprimary: 0,
    },
  ];
}

export function mockLaserStarveBlockData(): LaserStarveAndBlockData[] {
  return [
    {
      starve: 30.9,
      block: 64.9,
      isprimary: 1,
    },
    {
      starve: 21.6,
      block: 76.7,
      isprimary: 0,
    },
  ];
}

export function mockBendingAvailabilityData(): BendingAvailabilityData[] {
  return [
    {
      bend: 30.77,
      program: 4.43,
      setup: 0,
      warning: 0,
      error: 0.03,
      isprimary: 1,
    },
    {
      bend: 40.68,
      program: 4.06,
      setup: 0,
      warning: 0,
      error: 0.05,
      isprimary: 0,
    },
  ];
}

export function mockLaserProductivityData(): LaserProductivityData[] {
  return [
    {
      work: 26.62611,
      rest: 2.61166,
      isprimary: 1,
    },
    {
      work: 48.51555,
      rest: 16.62444,
      isprimary: 0,
    },
  ];
}

export function mockLaserOeeData(): LaserOEEData[] {
  return [
    {
      oee: 90.4,
      isprimary: 1,
    },
    {
      oee: 97.83,
      isprimary: 0,
    },
  ];
}

export function mockLaserCuttingTimeData(): LaserCuttingTimeData[] {
  return [
    {
      cutting_time: 41.83,
      isprimary: 1,
    },
    {
      cutting_time: 85.99,
      isprimary: 0,
    },
  ];
}

export function mockLaserOutputScrapData(): LaserOutputScrapData[] {
  return [
    {
      output: 6874.11,
      scrap: 3237.77,
      group_name: 'Material',
      isprimary: 1,
    },
    {
      output: 5553.32,
      scrap: 1997.02,
      group_name: 'Material',
      isprimary: 0,
    },
  ];
}

export function mockLaserAvailabilityHistData(): LaserAvailabilityHistoricalData[] {
  return [
    {
      work: 22.36167,
      error: 0,
      wait: 1.28333,
      idle: 0.35472,
      stop: 0,
      offline: 0,
      date: moment().subtract(6, 'days').format('YYYY-MM-DD'),
    },
    {
      work: 20.78944,
      error: 0.00167,
      wait: 1.71055,
      idle: 1.49805,
      stop: 0,
      offline: 0,
      date: moment().subtract(5, 'days').format('YYYY-MM-DD'),
    },
    {
      work: 15.27389,
      error: 0.13833,
      wait: 1.14916,
      idle: 7.3575,
      stop: 0,
      offline: 0.07972,
      date: moment().subtract(4, 'days').format('YYYY-MM-DD'),
    },
    {
      work: 16.05639,
      error: 0.02444,
      wait: 1.98972,
      idle: 5.92916,
      stop: 0,
      offline: 0,
      date: moment().subtract(3, 'days').format('YYYY-MM-DD'),
    },
    {
      work: 20.28917,
      error: 0.00222,
      wait: 1.62472,
      idle: 2.08361,
      stop: 0,
      offline: 0,
      date: moment().subtract(2, 'days').format('YYYY-MM-DD'),
    },
    {
      work: 16.96611,
      error: 0,
      wait: 3.14055,
      idle: 3.89305,
      stop: 0,
      offline: 0,
      date: moment().subtract(1, 'days').format('YYYY-MM-DD'),
    },
    {
      work: 9.84611,
      error: 0.00889,
      wait: 1.56055,
      idle: 3.12305,
      stop: 0,
      offline: 0.16333,
      date: moment().format('YYYY-MM-DD'),
    },
  ];
}

export function mockLaserProductivityHistData(): LaserProductivityHistoricalData[] {
  return [
    {
      productivity: 75.19605,
      date: moment().subtract(6, 'days').format('YYYY-MM-DD'),
    },
    {
      productivity: 86.24862,
      date: moment().subtract(5, 'days').format('YYYY-MM-DD'),
    },
    {
      productivity: 86.8471,
      date: moment().subtract(4, 'days').format('YYYY-MM-DD'),
    },
    {
      productivity: 74.26988,
      date: moment().subtract(3, 'days').format('YYYY-MM-DD'),
    },
    {
      productivity: 72.15016,
      date: moment().subtract(2, 'days').format('YYYY-MM-DD'),
    },
    {
      productivity: 75.78893,
      date: moment().subtract(1, 'days').format('YYYY-MM-DD'),
    },
    {
      productivity: 84.07368,
      date: moment().format('YYYY-MM-DD'),
    },
  ];
}

export function mockLaserOeeHistData(): LaserOeeHistoricalData[] {
  return [
    {
      oee: 93.17469,
      availability: 99.01503,
      performance: 94.10637,
      date: moment().subtract(6, 'days').format('YYYY-MM-DD'),
    },
    {
      oee: 86.62369,
      availability: 98.13424,
      performance: 87.98328,
      date: moment().subtract(5, 'days').format('YYYY-MM-DD'),
    },
    {
      oee: 63.85776,
      availability: 98.55537,
      performance: 64.79325,
      date: moment().subtract(4, 'days').format('YYYY-MM-DD'),
    },
    {
      oee: 66.90359,
      availability: 97.3634,
      performance: 68.6181,
      date: moment().subtract(3, 'days').format('YYYY-MM-DD'),
    },
    {
      oee: 84.52979,
      availability: 99.13888,
      performance: 85.24231,
      date: moment().subtract(2, 'days').format('YYYY-MM-DD'),
    },
    {
      oee: 70.5696,
      availability: 95.52888,
      performance: 74.14182,
      date: moment().subtract(1, 'days').format('YYYY-MM-DD'),
    },
    {
      oee: 67.75761,
      availability: 97.65933,
      performance: 69.36664,
      date: moment().format('YYYY-MM-DD'),
    },
  ];
}

export function mockLaserCuttingTimeHistData(): LaserCuttingTimeHistoricalData[] {
  return [
    {
      date: moment().subtract(6, 'days').format('YYYY-MM-DD'),
      unknown: 1.12654,
      channel0: 4.30889,
      channel1: 7.19444,
      channel2: 6.83916,
    },
    {
      date: moment().subtract(5, 'days').format('YYYY-MM-DD'),
      unknown: 1.26899,
      channel0: 6.48444,
      channel1: 9.0075,
      channel2: 3.58639,
    },
    {
      date: moment().subtract(4, 'days').format('YYYY-MM-DD'),
      unknown: 0.20397,
      channel0: 3.45194,
      channel1: 8.9875,
      channel2: 4.80444,
    },
    {
      date: moment().subtract(3, 'days').format('YYYY-MM-DD'),
      unknown: 0.95364,
      channel0: 5.69916,
      channel1: 6.13111,
      channel2: 3.75361,
    },
    {
      date: moment().subtract(2, 'days').format('YYYY-MM-DD'),
      unknown: 0.86235,
      channel0: 4.2225,
      channel1: 7.44389,
      channel2: 6.83472,
    },
    {
      date: moment().subtract(1, 'days').format('YYYY-MM-DD'),
      unknown: 1.57893,
      channel0: 6.78805,
      channel1: 5.43917,
      channel2: 4.91528,
    },
    {
      date: moment().format('YYYY-MM-DD'),
      unknown: 0.77854,
      channel0: 4.44139,
      channel1: 5.03639,
      channel2: 2.16417,
    },
  ];
}

export function mockLaserStarveBlockHistData(): LaserStarveAndBlockHistoricalData[] {
  return [
    {
      starve: 0,
      block: 48.5,
      date: moment().subtract(6, 'days').format('YYYY-MM-DD'),
    },
    {
      starve: 11.1,
      block: 44.4,
      date: moment().subtract(5, 'days').format('YYYY-MM-DD'),
    },
    {
      starve: 0,
      block: 11.6,
      date: moment().subtract(4, 'days').format('YYYY-MM-DD'),
    },
    {
      starve: 2.5,
      block: 11.5,
      date: moment().subtract(3, 'days').format('YYYY-MM-DD'),
    },
    {
      starve: 1.2,
      block: 12.2,
      date: moment().subtract(2, 'days').format('YYYY-MM-DD'),
    },
    {
      starve: 0.8,
      block: 62.9,
      date: moment().subtract(1, 'days').format('YYYY-MM-DD'),
    },
    {
      starve: 1.6,
      block: 71.6,
      date: moment().format('YYYY-MM-DD'),
    },
  ];
}

export function mockBendingPerformancePartData(): BendingPerformancePartData[] {
  return [
    {
      date: moment().subtract(1, 'days').format('YYYY-MM-DDTHH:mm:ss'),
      simple: 1.01,
      medium: 2.11,
      complex: 0.35,
    },
    {
      date: moment().subtract(20, 'hours').format('YYYY-MM-DDTHH:mm:ss'),
      simple: 0.84,
      medium: 2.34,
      complex: 0.54,
    },
    {
      date: moment().subtract(16, 'hours').format('YYYY-MM-DDTHH:mm:ss'),
      simple: 0.67,
      medium: 3.05,
      complex: 0.66,
    },
    {
      date: moment().subtract(12, 'hours').format('YYYY-MM-DDTHH:mm:ss'),
      simple: 0.34,
      medium: 2.9,
      complex: 0.98,
    },
    {
      date: moment().subtract(8, 'hours').format('YYYY-MM-DDTHH:mm:ss'),
      simple: 0.25,
      medium: 3.23,
      complex: 0.54,
    },
    {
      date: moment().subtract(4, 'hours').format('YYYY-MM-DDTHH:mm:ss'),
      simple: 1.54,
      medium: 2.87,
      complex: 0.63,
    },
    {
      date: moment().format('YYYY-MM-DDTHH:mm:ss'),
      simple: 1.33,
      medium: 2.67,
      complex: 0.78,
    },
  ];
}

export function mockBendingAvailabilityHistData(): BendingAvailabilityHistoricalData[] {
  return [
    {
      bend: 23.45916,
      program: 0.52305,
      setup: 0,
      warning: 0,
      error: 0.0175,
      date: moment().subtract(6, 'days').format('YYYY-MM-DD'),
    },
    {
      bend: 22.22778,
      program: 1.76917,
      setup: 0,
      warning: 0,
      error: 0,
      date: moment().subtract(5, 'days').format('YYYY-MM-DD'),
    },
    {
      bend: 22.90694,
      program: 1.08444,
      setup: 0,
      warning: 0,
      error: 0.00833,
      date: moment().subtract(4, 'days').format('YYYY-MM-DD'),
    },
    {
      bend: 21.48194,
      program: 2.49,
      setup: 0,
      warning: 0,
      error: 0.02778,
      date: moment().subtract(3, 'days').format('YYYY-MM-DD'),
    },
    {
      bend: 22.3175,
      program: 1.62667,
      setup: 0,
      warning: 0,
      error: 0.05555,
      date: moment().subtract(2, 'days').format('YYYY-MM-DD'),
    },
    {
      bend: 22.10805,
      program: 1.88055,
      setup: 0,
      warning: 0,
      error: 0.01111,
      date: moment().subtract(1, 'days').format('YYYY-MM-DD'),
    },
    {
      bend: 12.35667,
      program: 1.18778,
      setup: 0,
      warning: 0,
      error: 0.00556,
      date: moment().format('YYYY-MM-DD'),
    },
  ];
}

export function mockBendingTimeBetweenBendHistData(): BendingTimeBetweenBendHistoricalData[] {
  return [
    {
      date: moment().subtract(6, 'days').format('YYYY-MM-DD'),
      simple: 17.5,
      medium: 19.66667,
      complex: 10.34,
    },
    {
      date: moment().subtract(5, 'days').format('YYYY-MM-DD'),
      simple: 23,
      medium: 17,
      complex: 12,
    },
    {
      date: moment().subtract(4, 'days').format('YYYY-MM-DD'),
      simple: 13.5,
      medium: 17.83333,
      complex: 15,
    },
    {
      date: moment().subtract(3, 'days').format('YYYY-MM-DD'),
      simple: 14.83333,
      medium: 15.83333,
      complex: 19,
    },
    {
      date: moment().subtract(2, 'days').format('YYYY-MM-DD'),
      simple: 13.5,
      medium: 17,
      complex: 22,
    },
    {
      date: moment().subtract(1, 'days').format('YYYY-MM-DD'),
      simple: 18.66667,
      medium: 19.5,
      complex: 18,
    },
    {
      date: moment().format('YYYY-MM-DD'),
      simple: 16,
      medium: 17.5,
      complex: 16,
    },
  ];
}

export function mockBendingPerformancePartHistData(): BendingPerformancePartHistoricalData[] {
  return [
    {
      date: moment().subtract(6, 'days').format('YYYY-MM-DD'),
      simple: 0.83221,
      medium: 2.35525,
      complex: 2.05987,
    },
    {
      date: moment().subtract(5, 'days').format('YYYY-MM-DD'),
      simple: 3.48104,
      medium: 3.20724,
      complex: 2.94513,
    },
    {
      date: moment().subtract(4, 'days').format('YYYY-MM-DD'),
      simple: 0.84638,
      medium: 4.40295,
      complex: 2.59782,
    },
    {
      date: moment().subtract(3, 'days').format('YYYY-MM-DD'),
      simple: 1.54248,
      medium: 1.84715,
      complex: 1.76543,
    },
    {
      date: moment().subtract(2, 'days').format('YYYY-MM-DD'),
      simple: 0.43417,
      medium: 3.35331,
      complex: 2.39694,
    },
    {
      date: moment().subtract(1, 'days').format('YYYY-MM-DD'),
      simple: 1.15348,
      medium: 5.80508,
      complex: 2.26548,
    },
    {
      date: moment().format('YYYY-MM-DD'),
      simple: 1.31678,
      medium: 1.98597,
      complex: 2.06587,
    },
  ];
}

export function mockBendingPerformanceHistData(): BendingPerformanceHistoricalData[] {
  return [
    {
      date: moment().subtract(6, 'days').format('YYYY-MM-DD'),
      tool_changes: 2,
      part_types: 46,
    },
    {
      date: moment().subtract(5, 'days').format('YYYY-MM-DD'),
      tool_changes: 13,
      part_types: 40,
    },
    {
      date: moment().subtract(4, 'days').format('YYYY-MM-DD'),
      tool_changes: 28,
      part_types: 44,
    },
    {
      date: moment().subtract(3, 'days').format('YYYY-MM-DD'),
      tool_changes: 24,
      part_types: 45,
    },
    {
      date: moment().subtract(2, 'days').format('YYYY-MM-DD'),
      tool_changes: 19,
      part_types: 45,
    },
    {
      date: moment().subtract(1, 'days').format('YYYY-MM-DD'),
      tool_changes: 40,
      part_types: 83,
    },
    {
      date: moment().format('YYYY-MM-DD'),
      tool_changes: 26,
      part_types: 30,
    },
  ];
}

export function mockBendingNumberOfBendsTimelineData(): BendingNumberOfBendsTimelineData[] {
  return [
    {
      date: moment().subtract(6, 'days').format('YYYY-MM-DD'),
      bends: 1516,
    },
    {
      date: moment().subtract(5, 'days').format('YYYY-MM-DD'),
      bends: 912,
    },
    {
      date: moment().subtract(4, 'days').format('YYYY-MM-DD'),
      bends: 339,
    },
    {
      date: moment().subtract(3, 'days').format('YYYY-MM-DD'),
      bends: 551,
    },
    {
      date: moment().subtract(2, 'days').format('YYYY-MM-DD'),
      bends: 997,
    },
    {
      date: moment().subtract(1, 'days').format('YYYY-MM-DD'),
      bends: 1426,
    },
    {
      date: moment().format('YYYY-MM-DD'),
      bends: 781,
    },
  ];
}

export function mockLaserOutputScrapHistData(): LaserOutputScrapHistoricalData[] {
  return [
    {
      output: 1153.27366,
      scrap: 1025.85134,
      group_name: 'Material',
      date: moment().subtract(6, 'days').format('YYYY-MM-DD'),
    },
    {
      output: 1311.96004,
      scrap: 1174.7102,
      group_name: 'Material',
      date: moment().subtract(5, 'days').format('YYYY-MM-DD'),
    },
    {
      output: 2703.03778,
      scrap: 2458.74542,
      group_name: 'Material',
      date: moment().subtract(4, 'days').format('YYYY-MM-DD'),
    },
    {
      output: 2650.76847,
      scrap: 2206.72832,
      group_name: 'Material',
      date: moment().subtract(3, 'days').format('YYYY-MM-DD'),
    },
    {
      output: 2653.1475,
      scrap: 1537.74393,
      group_name: 'Material',
      date: moment().subtract(2, 'days').format('YYYY-MM-DD'),
    },
    {
      output: 2458.78619,
      scrap: 926.94934,
      group_name: 'Material',
      date: moment().subtract(1, 'days').format('YYYY-MM-DD'),
    },
    {
      output: 1653.7241,
      scrap: 486.25655,
      group_name: 'Material',
      date: moment().format('YYYY-MM-DD'),
    },
  ];
}

export function mockBendingTimeBetweenBendData(): BendingTimeBetweenBendData[] {
  return [
    {
      category_name: 'medium',
      time_between_bend: 17.5,
      isprimary: 1,
    },
    {
      category_name: 'simple',
      time_between_bend: 16,
      isprimary: 1,
    },
    {
      category_name: 'medium',
      time_between_bend: 19.5,
      isprimary: 0,
    },
    {
      category_name: 'simple',
      time_between_bend: 18.67,
      isprimary: 0,
    },
  ];
}

export function mockBendingTopPartsData(): BendingTopPartsData[] {
  return [
    {
      PartName: 'Part 1',
      PartCount: 44,
      isprimary: 1,
    },
    {
      PartName: 'Part 2',
      PartCount: 34,
      isprimary: 1,
    },
    {
      PartName: 'Part 3',
      PartCount: 28,
      isprimary: 1,
    },
    {
      PartName: 'Part 4',
      PartCount: 23,
      isprimary: 1,
    },
  ];
}

export function mockSMBSMapData(): SMBSMapData[] {
  return [
    {
      bill_to_customer_no_: 'CUSTOMER1',
      n_transactions: 24,
      amount: 87612.47,
      converted_amount: 6533.31,
      name: 'Customer 1',
      latitude: 48.170941,
      longitude: 7.19348,
      dirty: false,
    },
    {
      bill_to_customer_no_: 'CUSTOMER2',
      n_transactions: 2,
      amount: 1673.44,
      converted_amount: 1673.44,
      name: 'Customer 2',
      latitude: 51.2442798,
      longitude: 7.08555,
      dirty: false,
    },
    {
      bill_to_customer_no_: 'CUSTOMER3',
      n_transactions: 31,
      amount: 52305.14,
      converted_amount: 45995.19,
      name: 'Customer 3',
      latitude: 47.0338781,
      longitude: 9.0775529,
      dirty: false,
    },
    {
      bill_to_customer_no_: 'CUSTOMER4',
      n_transactions: 8,
      amount: 31077.07,
      converted_amount: 16440.84,
      name: 'Customer 4',
      latitude: 50.9679738,
      longitude: 5.7662196,
      dirty: false,
    },
    {
      bill_to_customer_no_: 'CUSTOMER5',
      n_transactions: 1,
      amount: 806.82,
      converted_amount: 806.82,
      name: 'Customer 5',
      latitude: 49.9694139,
      longitude: 9.1121383,
      dirty: false,
    },
    {
      bill_to_customer_no_: 'CUSTOMER6',
      n_transactions: 1,
      amount: 3954.56,
      converted_amount: 0,
      name: 'Customer 6',
      latitude: 52.3870446,
      longitude: 6.4542947,
      dirty: false,
    },
    {
      bill_to_customer_no_: 'CUSTOMER7',
      n_transactions: 1,
      amount: 41,
      converted_amount: 0,
      name: 'Customer 7',
      latitude: 48.950977,
      longitude: 7.767071,
      dirty: false,
    },
    {
      bill_to_customer_no_: 'CUSTOMER8',
      n_transactions: 1,
      amount: 1332.01,
      converted_amount: 1332.01,
      name: 'Customer 8',
      latitude: 50.5529105,
      longitude: 11.7869553,
      dirty: false,
    },
    {
      bill_to_customer_no_: 'CUSTOMER9',
      n_transactions: 9,
      amount: 18434.1,
      converted_amount: 18434.1,
      name: 'Customer 9',
      latitude: 51.9908557,
      longitude: 8.8166299,
      dirty: false,
    },
    {
      bill_to_customer_no_: 'CUSTOMER10',
      n_transactions: 4,
      amount: 1932.36,
      converted_amount: 1932.36,
      name: 'Customer 10',
      latitude: 51.4556432,
      longitude: 7.0115552,
      dirty: false,
    },
  ];
}

export function mockOpenSalesFunnelData(): OpenSalesFunnelData[] {
  return [
    {
      open_quotes: 1794326.57,
      quotes: 1890468.87,
      open_sold: 821248.18,
      sold: 961945.12,
      open_invoiced: 974452.42,
      invoiced: 1087553.57,
    },
  ];
}

export function mockQuotesFiguresData(): QuotesFiguresData {
  return {
    nQuotes: 7158,
    nAccepted: 5308,
    nRejected: 1385,
    nCustomersPerGroup: '4/13/586',
    nCustomers: 603,
    newCustomers: 538,
    avgConversionTime: 9.736435568952524,
    avgAmount: 4488.102806649902,
    avgConvertedAmount: 4335.368419366993,
  };
}

export function mockSalesFiguresData(): SalesFiguresData {
  return {
    nSalesOrders: 5751,
    to_be_paid_amount: 902820.545726,
    overdue_invoices_amount: 648355.965855,
    nCustomersPerGroup: '4/12/479',
    nCustomers: 499,
    newCustomers: 440,
    maxAmount: 230659.42,
    avgAmount: 3887.72153,
    invoiced_amount: 22402481.46,
  };
}

export function mockBusinessOverviewFiguresData(): BusinessOverviewFiguresData {
  return {
    open_quotes: '465/1794326',
    open_orders: '217/821248',
    open_invoices: '251/974452',
    quotes_amount: 32510232.28,
    quotes_converted_amount: 23121818.35,
    orders_amount: 22627507.02,
    newCustomers: 635,
    nCustomers: 635,
    overdue_invoices: '393/648355',
  };
}

export function mockManufacturingFiguresData(): ManufacturingFiguresData {
  return {
    total_estimated_hours: 66,
    total_executed_hours: 66,
    ratio_est_actual: 1.0,
    'delayPO/delayPOL': '231/199',
    scrap_ratio: 0.749505,
    estimated_capacity: 92.1725712262,
    actual_capacity: 91.2289254717,
  };
}

export function mockQuotesAbcCustomersData(): QuotesAbcCustomersData[] {
  return [
    {
      name: 'Customer 1',
      no_: 'CUSTOMER1',
      n_quotes: 24,
      n_accepted: 4,
      amount: 87612.47,
      converted_amount: 6533.31,
      category: Category.C,
      conversion_rate: 0.07457,
      new_customer: true,
      overdue: false,
    },
    {
      name: 'Customer 2',
      no_: 'CUSTOMER2',
      n_quotes: 2,
      n_accepted: 2,
      amount: 1673.44,
      converted_amount: 1673.44,
      category: Category.C,
      conversion_rate: 1,
      new_customer: true,
      overdue: true,
    },
    {
      name: 'Customer 3',
      no_: 'CUSTOMER3',
      n_quotes: 31,
      n_accepted: 22,
      amount: 52305.14,
      converted_amount: 45995.19,
      category: Category.A,
      conversion_rate: 0.879362,
      new_customer: false,
      overdue: true,
    },
    {
      name: 'Customer 4',
      no_: 'CUSTOMER4',
      n_quotes: 8,
      n_accepted: 4,
      amount: 31077.07,
      converted_amount: 16440.84,
      category: Category.C,
      conversion_rate: 0.529034,
      new_customer: true,
      overdue: true,
    },
    {
      name: 'Customer 5',
      no_: 'CUSTOMER5',
      n_quotes: 1,
      n_accepted: 1,
      amount: 806.82,
      converted_amount: 806.82,
      category: Category.C,
      conversion_rate: 1,
      new_customer: true,
      overdue: true,
    },
    {
      name: 'Customer 6',
      no_: 'CUSTOMER6',
      n_quotes: 1,
      n_accepted: 0,
      amount: 3954.56,
      converted_amount: 0,
      category: Category.C,
      conversion_rate: 0,
      new_customer: true,
      overdue: false,
    },
    {
      name: 'Customer 7',
      no_: 'CUSTOMER7',
      n_quotes: 1,
      n_accepted: 0,
      amount: 41,
      converted_amount: 0,
      category: Category.C,
      conversion_rate: 0,
      new_customer: true,
      overdue: false,
    },
    {
      name: 'Customer 8',
      no_: 'CUSTOMER8',
      n_quotes: 1,
      n_accepted: 1,
      amount: 1332.01,
      converted_amount: 1332.01,
      category: Category.C,
      conversion_rate: 1,
      new_customer: true,
      overdue: true,
    },
    {
      name: 'Customer 9',
      no_: 'CUSTOMER9',
      n_quotes: 9,
      n_accepted: 9,
      amount: 18434.1,
      converted_amount: 18434.1,
      category: Category.B,
      conversion_rate: 1,
      new_customer: true,
      overdue: true,
    },
    {
      name: 'Customer 10',
      no_: 'CUSTOMER10',
      n_quotes: 4,
      n_accepted: 4,
      amount: 1932.36,
      converted_amount: 1932.36,
      category: Category.C,
      conversion_rate: 1,
      new_customer: true,
      overdue: true,
    },
  ];
}

export function mockQuotesConvertedAmountTimelineData(): QuoteConversionTimelineData[] {
  return [
    {
      date: moment().format('YYYY-01'),
      amount: 1729105.16,
      converted_amount: 1723540.86,
    },
    {
      date: moment().format('YYYY-02'),
      amount: 1465314.99,
      converted_amount: 1435491.46,
    },
    {
      date: moment().format('YYYY-03'),
      amount: 1320280.71,
      converted_amount: 1242845.12,
    },
    {
      date: moment().format('YYYY-04'),
      amount: 1259457.9,
      converted_amount: 1214262.71,
    },
    {
      date: moment().format('YYYY-05'),
      amount: 1778582.53,
      converted_amount: 1578790.87,
    },
    {
      date: moment().format('YYYY-06'),
      amount: 2078989.37,
      converted_amount: 1903502.11,
    },
    {
      date: moment().format('YYYY-07'),
      amount: 4393404.6,
      converted_amount: 3586697.25,
    },
    {
      date: moment().format('YYYY-08'),
      amount: 5928264.8,
      converted_amount: 4093416.71,
    },
    {
      date: moment().format('YYYY-09'),
      amount: 5152099.73,
      converted_amount: 2825534.21,
    },
    {
      date: moment().format('YYYY-10'),
      amount: 3534103.52,
      converted_amount: 1716151.7,
    },
    {
      date: moment().format('YYYY-11'),
      amount: 3191409.44,
      converted_amount: 1595760.27,
    },
    {
      date: moment().format('YYYY-12'),
      amount: 294827.14,
      converted_amount: 96142.3,
    },
  ];
}

export function mockSalesTimelineData(): SalesTimelineData[] {
  return [
    {
      date: moment().format('YYYY-01'),
      sold_amount: 1641913.49,
    },
    {
      date: moment().format('YYYY-02'),
      sold_amount: 1341646.46,
    },
    {
      date: moment().format('YYYY-03'),
      sold_amount: 966843.45,
    },
    {
      date: moment().format('YYYY-04'),
      sold_amount: 1143330.43,
    },
    {
      date: moment().format('YYYY-05'),
      sold_amount: 1570871.35,
    },
    {
      date: moment().format('YYYY-06'),
      sold_amount: 1819526.17,
    },
    {
      date: moment().format('YYYY-07'),
      sold_amount: 2831621.75,
    },
    {
      date: moment().format('YYYY-08'),
      sold_amount: 3760501.6,
    },
    {
      date: moment().format('YYYY-09'),
      sold_amount: 3347670.27,
    },
    {
      date: moment().format('YYYY-10'),
      sold_amount: 2022689.88,
    },
    {
      date: moment().format('YYYY-11'),
      sold_amount: 1562570.17,
    },
    {
      date: moment().format('YYYY-12'),
      sold_amount: 349101.5,
    },
  ];
}

export function mockQuotesConversionRatesData(): QuotesConversionRatesData[] {
  return [
    {
      category: Category.A,
      amount_conv_rate: 0.877701,
      quotes_conv_rate: 0.8990353697749196,
    },
    {
      category: Category.B,
      amount_conv_rate: 0.794564,
      quotes_conv_rate: 0.8160696999031946,
    },
    {
      category: Category.C,
      amount_conv_rate: 0.61863,
      quotes_conv_rate: 0.6711159737417943,
    },
  ];
}

export function mockQuotesCustomerEvolutionData(): QuotesCustomerEvolutionData[] {
  return [
    {
      converted_amount: 53519.14,
      amount: 53519.14,
      bill_to_customer_no_: 'CUSTOMER1',
      date: moment().format('YYYY-01'),
    },
    {
      converted_amount: 62669.25,
      amount: 62669.25,
      bill_to_customer_no_: 'CUSTOMER1',
      date: moment().format('YYYY-02'),
    },
    {
      converted_amount: 47257.98,
      amount: 56005.93,
      bill_to_customer_no_: 'CUSTOMER1',
      date: moment().format('YYYY-03'),
    },
    {
      converted_amount: 47238.41,
      amount: 47238.41,
      bill_to_customer_no_: 'CUSTOMER1',
      date: moment().format('YYYY-04'),
    },
    {
      converted_amount: 91469.28,
      amount: 91469.28,
      bill_to_customer_no_: 'CUSTOMER1',
      date: moment().format('YYYY-05'),
    },
    {
      converted_amount: 66955.9,
      amount: 66955.9,
      bill_to_customer_no_: 'CUSTOMER1',
      date: moment().format('YYYY-06'),
    },
    {
      converted_amount: 365702.75,
      amount: 378854.68,
      bill_to_customer_no_: 'CUSTOMER1',
      date: moment().format('YYYY-07'),
    },
    {
      converted_amount: 345685.82,
      amount: 397409.72,
      bill_to_customer_no_: 'CUSTOMER1',
      date: moment().format('YYYY-08'),
    },
    {
      converted_amount: 198871.17,
      amount: 210548.09,
      bill_to_customer_no_: 'CUSTOMER1',
      date: moment().format('YYYY-09'),
    },
    {
      converted_amount: 67534.32,
      amount: 116840.14,
      bill_to_customer_no_: 'CUSTOMER1',
      date: moment().format('YYYY-10'),
    },
    {
      converted_amount: 15879.48,
      amount: 52117.87,
      bill_to_customer_no_: 'CUSTOMER1',
      date: moment().format('YYYY-11'),
    },
    {
      converted_amount: 1991.59,
      amount: 1991.59,
      bill_to_customer_no_: 'CUSTOMER1',
      date: moment().format('YYYY-12'),
    },
  ];
}

export function mockQuotesSizeData(): QuotesSizeData[] {
  return [
    {
      bin_start: 0,
      bin_end: 75.314333,
      not_open_quotes: 117,
      open_quotes: 3,
      divisor: 430.18,
    },
    {
      bin_start: 75.314333,
      bin_end: 150.628666,
      not_open_quotes: 44,
      open_quotes: 3,
      divisor: 767.53,
    },
    {
      bin_start: 150.628666,
      bin_end: 225.942999,
      not_open_quotes: 90,
      open_quotes: 5,
      divisor: 1342.92,
    },
    {
      bin_start: 225.942999,
      bin_end: 301.257332,
      not_open_quotes: 217,
      open_quotes: 8,
      divisor: null,
    },
    {
      bin_start: 301.257332,
      bin_end: 376.571665,
      not_open_quotes: 244,
      open_quotes: 12,
      divisor: null,
    },
    {
      bin_start: 376.571665,
      bin_end: 451.885998,
      not_open_quotes: 248,
      open_quotes: 15,
      divisor: null,
    },
    {
      bin_start: 451.885998,
      bin_end: 527.200331,
      not_open_quotes: 254,
      open_quotes: 16,
      divisor: null,
    },
    {
      bin_start: 527.200331,
      bin_end: 602.514664,
      not_open_quotes: 210,
      open_quotes: 9,
      divisor: null,
    },
    {
      bin_start: 602.514664,
      bin_end: 677.828997,
      not_open_quotes: 177,
      open_quotes: 8,
      divisor: null,
    },
    {
      bin_start: 677.828997,
      bin_end: 753.14333,
      not_open_quotes: 160,
      open_quotes: 10,
      divisor: null,
    },
    {
      bin_start: 753.14333,
      bin_end: 828.457663,
      not_open_quotes: 149,
      open_quotes: 7,
      divisor: null,
    },
    {
      bin_start: 828.457663,
      bin_end: 903.771996,
      not_open_quotes: 153,
      open_quotes: 7,
      divisor: null,
    },
    {
      bin_start: 903.771996,
      bin_end: 979.086329,
      not_open_quotes: 155,
      open_quotes: 4,
      divisor: null,
    },
    {
      bin_start: 979.086329,
      bin_end: 1054.400662,
      not_open_quotes: 99,
      open_quotes: 5,
      divisor: null,
    },
    {
      bin_start: 1054.400662,
      bin_end: 1129.714995,
      not_open_quotes: 114,
      open_quotes: 6,
      divisor: null,
    },
    {
      bin_start: 1129.714995,
      bin_end: 1205.029328,
      not_open_quotes: 90,
      open_quotes: 5,
      divisor: null,
    },
    {
      bin_start: 1205.029328,
      bin_end: 1280.343661,
      not_open_quotes: 87,
      open_quotes: 9,
      divisor: null,
    },
    {
      bin_start: 1280.343661,
      bin_end: 1355.657994,
      not_open_quotes: 94,
      open_quotes: 5,
      divisor: null,
    },
    {
      bin_start: 1355.657994,
      bin_end: 1430.972327,
      not_open_quotes: 79,
      open_quotes: 6,
      divisor: null,
    },
    {
      bin_start: 1430.972327,
      bin_end: 1506.28666,
      not_open_quotes: 80,
      open_quotes: 5,
      divisor: null,
    },
    {
      bin_start: 1506.28666,
      bin_end: 1581.600993,
      not_open_quotes: 87,
      open_quotes: 6,
      divisor: null,
    },
    {
      bin_start: 1581.600993,
      bin_end: 1656.915326,
      not_open_quotes: 80,
      open_quotes: 6,
      divisor: null,
    },
    {
      bin_start: 1656.915326,
      bin_end: 1732.229659,
      not_open_quotes: 90,
      open_quotes: 3,
      divisor: null,
    },
    {
      bin_start: 1732.229659,
      bin_end: 1807.543992,
      not_open_quotes: 71,
      open_quotes: 5,
      divisor: null,
    },
    {
      bin_start: 1807.543992,
      bin_end: 1882.858325,
      not_open_quotes: 67,
      open_quotes: 2,
      divisor: null,
    },
    {
      bin_start: 1882.858325,
      bin_end: 1958.172658,
      not_open_quotes: 63,
      open_quotes: 4,
      divisor: null,
    },
    {
      bin_start: 1958.172658,
      bin_end: 2033.486991,
      not_open_quotes: 66,
      open_quotes: 4,
      divisor: null,
    },
    {
      bin_start: 2033.486991,
      bin_end: 2108.801324,
      not_open_quotes: 69,
      open_quotes: 3,
      divisor: null,
    },
    {
      bin_start: 2108.801324,
      bin_end: 2184.115657,
      not_open_quotes: 56,
      open_quotes: 1,
      divisor: null,
    },
    {
      bin_start: 2184.115657,
      bin_end: 2259.42999,
      not_open_quotes: 44,
      open_quotes: 2,
      divisor: null,
    },
  ];
}

export function mockQuotesMaterialsData(): QuotesMaterialsData[] {
  return [
    {
      date: moment().subtract(3, 'months').format('YYYY-MM'),
      mat_group: 'Material 1',
      weight: 294069.08,
      converted_weight: 181209.83,
    },
    {
      date: moment().subtract(3, 'months').format('YYYY-MM'),
      mat_group: 'Material 2',
      weight: 69994.71,
      converted_weight: 28924.91,
    },
    {
      date: moment().subtract(3, 'months').format('YYYY-MM'),
      mat_group: 'OTHERS',
      weight: 33893.59,
      converted_weight: 15960.29,
    },
    {
      date: moment().subtract(3, 'months').format('YYYY-MM'),
      mat_group: 'Material 3',
      weight: 27234.98,
      converted_weight: 15742.75,
    },
    {
      date: moment().subtract(2, 'months').format('YYYY-MM'),
      mat_group: 'Material 1',
      weight: 198321.66,
      converted_weight: 119103.55,
    },
    {
      date: moment().subtract(2, 'months').format('YYYY-MM'),
      mat_group: 'Material 2',
      weight: 39618.2,
      converted_weight: 21772.71,
    },
    {
      date: moment().subtract(2, 'months').format('YYYY-MM'),
      mat_group: 'OTHERS',
      weight: 24431.13,
      converted_weight: 4641.66,
    },
    {
      date: moment().subtract(2, 'months').format('YYYY-MM'),
      mat_group: 'Material 3',
      weight: 13821.56,
      converted_weight: 6602.9,
    },
    {
      date: moment().subtract(1, 'months').format('YYYY-MM'),
      mat_group: 'Material 1',
      weight: 247188.18,
      converted_weight: 126523.76,
    },
    {
      date: moment().subtract(1, 'months').format('YYYY-MM'),
      mat_group: 'Material 2',
      weight: 31520.83,
      converted_weight: 16833.96,
    },
    {
      date: moment().subtract(1, 'months').format('YYYY-MM'),
      mat_group: 'OTHERS',
      weight: 41874.56,
      converted_weight: 18966.25,
    },
    {
      date: moment().subtract(1, 'months').format('YYYY-MM'),
      mat_group: 'Material 3',
      weight: 17087.12,
      converted_weight: 7467.79,
    },
    {
      date: moment().format('YYYY-MM'),
      mat_group: 'Material 1',
      weight: 36834.36,
      converted_weight: 6586.71,
    },
    {
      date: moment().format('YYYY-MM'),
      mat_group: 'Material 2',
      weight: 2291.59,
      converted_weight: 1532.66,
    },
    {
      date: moment().format('YYYY-MM'),
      mat_group: 'OTHERS',
      weight: 1547.86,
      converted_weight: 736.28,
    },
    {
      date: moment().format('YYYY-MM'),
      mat_group: 'Material 3',
      weight: 10424.01,
      converted_weight: 2913.87,
    },
  ];
}

export function mockQuotesMaterialsListData(): QuotedMaterialsListData[] {
  return [
    {
      mat_group: 'Material 1',
      weight: 1930431.75,
      converted_weight: 1457067.03,
    },
    {
      mat_group: 'Material 2',
      weight: 265102.11,
      converted_weight: 188241.19,
    },
    {
      mat_group: 'Material 3',
      weight: 191465.73,
      converted_weight: 134398.55,
    },
    {
      mat_group: 'Material 4',
      weight: 124223.42,
      converted_weight: 104485.23,
    },
    {
      mat_group: 'Material 5',
      weight: 114796.91,
      converted_weight: 73769.72,
    },
    {
      mat_group: 'Material 6',
      weight: 89257.07,
      converted_weight: 58726.68,
    },
    {
      mat_group: 'OTHERS',
      weight: 16618.35,
      converted_weight: 9619.79,
    },
  ];
}

export function mockSalesCustomerGroupsData(): SalesCustomerGroupsData[] {
  return [
    {
      customer: 'Customer 1',
      sales: 283962.39,
      quotes_converted: 286903.05,
      quotes_not_converted: -2940.66,
      sales_invoiced: 281622.17,
      sales_not_invoiced: 2340.22,
      invoices_paid: 281185.94,
      invoices_not_paid: 436.23,
      new_customer: 1,
      payments_pending: 1,
      invoices_expired: 0,
      category_sales: Category.C,
      category_invoice: Category.C,
      category_paid: Category.C,
    },
    {
      customer: 'Customer 2',
      sales: 355483.96,
      quotes_converted: 391798.07,
      quotes_not_converted: -36314.11,
      sales_invoiced: 327674.65,
      sales_not_invoiced: 27809.31,
      invoices_paid: 308057.14,
      invoices_not_paid: 19617.51,
      new_customer: 1,
      payments_pending: 1,
      invoices_expired: 1,
      category_sales: Category.C,
      category_invoice: Category.C,
      category_paid: Category.C,
    },
    {
      customer: 'Customer 3',
      sales: 396560.35,
      quotes_converted: 164683.73,
      quotes_not_converted: 231876.62,
      sales_invoiced: 396560.35,
      sales_not_invoiced: 0,
      invoices_paid: 352496.04,
      invoices_not_paid: 44064.31,
      new_customer: 1,
      payments_pending: 1,
      invoices_expired: 1,
      category_sales: Category.C,
      category_invoice: Category.C,
      category_paid: Category.C,
    },
    {
      customer: 'Customer 4',
      sales: 631192.55,
      quotes_converted: 666394.65,
      quotes_not_converted: -35202.1,
      sales_invoiced: 454454.94,
      sales_not_invoiced: 176737.61,
      invoices_paid: 434276.54,
      invoices_not_paid: 20178.4,
      new_customer: 0,
      payments_pending: 1,
      invoices_expired: 0,
      category_sales: Category.C,
      category_invoice: Category.C,
      category_paid: Category.C,
    },
    {
      customer: 'Customer 5',
      sales: 447678.13,
      quotes_converted: 463187.31,
      quotes_not_converted: -15509.18,
      sales_invoiced: 444384.18,
      sales_not_invoiced: 3293.95,
      invoices_paid: 442691.31,
      invoices_not_paid: 1692.87,
      new_customer: 0,
      payments_pending: 1,
      invoices_expired: 1,
      category_sales: Category.C,
      category_invoice: Category.C,
      category_paid: Category.C,
    },
    {
      customer: 'Customer 6',
      sales: 525615.11,
      quotes_converted: 537849.35,
      quotes_not_converted: -12234.24,
      sales_invoiced: 477146.53,
      sales_not_invoiced: 48468.58,
      invoices_paid: 463293.91,
      invoices_not_paid: 13852.62,
      new_customer: 0,
      payments_pending: 1,
      invoices_expired: 1,
      category_sales: Category.C,
      category_invoice: Category.C,
      category_paid: Category.C,
    },
    {
      customer: 'Customer 7',
      sales: 573366.56,
      quotes_converted: 591891.14,
      quotes_not_converted: -18524.58,
      sales_invoiced: 514910.87,
      sales_not_invoiced: 58455.69,
      invoices_paid: 509569.62,
      invoices_not_paid: 5341.25,
      new_customer: 0,
      payments_pending: 1,
      invoices_expired: 1,
      category_sales: Category.C,
      category_invoice: Category.C,
      category_paid: Category.C,
    },
    {
      customer: 'Customer 8',
      sales: 1356922.49,
      quotes_converted: 1365103.84,
      quotes_not_converted: -8181.35,
      sales_invoiced: 1163840.31,
      sales_not_invoiced: 193082.18,
      invoices_paid: 1020711.03,
      invoices_not_paid: 143129.28,
      new_customer: 0,
      payments_pending: 1,
      invoices_expired: 1,
      category_sales: Category.B,
      category_invoice: Category.B,
      category_paid: Category.B,
    },
    {
      customer: 'Customer 9',
      sales: 1408033.29,
      quotes_converted: 1459675.77,
      quotes_not_converted: -51642.48,
      sales_invoiced: 1321809.1,
      sales_not_invoiced: 86224.19,
      invoices_paid: 1302049.37,
      invoices_not_paid: 19759.73,
      new_customer: 0,
      payments_pending: 1,
      invoices_expired: 1,
      category_sales: Category.B,
      category_invoice: Category.B,
      category_paid: Category.B,
    },
    {
      customer: 'Customer 10',
      sales: 3615787.29,
      quotes_converted: 3766302.45,
      quotes_not_converted: -150515.16,
      sales_invoiced: 3401376.57,
      sales_not_invoiced: 214410.72,
      invoices_paid: 3368755.58,
      invoices_not_paid: 32620.99,
      new_customer: 0,
      payments_pending: 1,
      invoices_expired: 1,
      category_sales: Category.A,
      category_invoice: Category.A,
      category_paid: Category.A,
    },
  ];
}

export function mockTopSalespeopleData(): SalesTopBySalesData[] {
  return [
    {
      Salesperson: 'Salesperson 1',
      total_sales: 7031043.24,
      total_converted: 6973524.69,
      total_invoice: 7723082.44,

      amount_conversion: 8823082.44,
      quotes_conversion: 3000000,
      sales_item_changes: 4000000,
      number_of_sales: 12000000,
    },
    {
      Salesperson: 'Salesperson 2',
      total_sales: 6461730.63,
      total_converted: 6605930.64,
      total_invoice: 6670958.98,

      amount_conversion: 7023082.44,
      quotes_conversion: 2000000,
      sales_item_changes: 1000000,
      number_of_sales: 32000000,
    },
    {
      Salesperson: 'Salesperson 3',
      total_sales: 2913177.88,
      total_converted: 3308777.46,
      total_invoice: 2316961.3,

      amount_conversion: 3823082.44,
      quotes_conversion: 1000000,
      sales_item_changes: 7000000,
      number_of_sales: 22000000,
    },
    {
      Salesperson: 'Others',
      total_sales: 5972535.8,
      total_converted: 5986637.89,
      total_invoice: 5691478.74,

      amount_conversion: 9823082.44,
      quotes_conversion: 6000000,
      sales_item_changes: 9000000,
      number_of_sales: 32000000,
    },
  ];
}

export function mockSalesPerSalespersonTimelineData(): SalesPerSalespersonTimelineData[] {
  return [
    {
      date: '2022-10',
      salesperson: 'Hans-Günther Bonbach',
      amount: 644288.3,
      category: Category.A,
    },
    {
      date: '2022-10',
      salesperson: 'Hans-Günther Bonbach',
      amount: 153295.9,
      category: Category.B,
    },
    {
      date: '2022-10',
      salesperson: 'Hans-Günther Bonbach',
      amount: 160615.83,
      category: Category.C,
    },
    {
      date: '2022-10',
      salesperson: 'Nelli Losekann',
      amount: 365305.69,
      category: Category.A,
    },
    {
      date: '2022-10',
      salesperson: 'Nelli Losekann',
      amount: 178893.78,
      category: Category.B,
    },
    {
      date: '2022-10',
      salesperson: 'Nelli Losekann',
      amount: 407711.47,
      category: Category.C,
    },
    {
      date: '2022-10',
      salesperson: 'Susi Mielcarek',
      amount: 46887.78,
      category: Category.B,
    },
    {
      date: '2022-10',
      salesperson: 'Susi Mielcarek',
      amount: 157119.48,
      category: Category.C,
    },
    {
      date: '2022-11',
      salesperson: 'Hans-Günther Bonbach',
      amount: 67067.21,
      category: Category.A,
    },
    {
      date: '2022-11',
      salesperson: 'Hans-Günther Bonbach',
      amount: 18251.34,
      category: Category.B,
    },
    {
      date: '2022-11',
      salesperson: 'Hans-Günther Bonbach',
      amount: 169534.65,
      category: Category.C,
    },
    {
      date: '2022-11',
      salesperson: 'Nelli Losekann',
      amount: 118873.89,
      category: Category.A,
    },
    {
      date: '2022-11',
      salesperson: 'Nelli Losekann',
      amount: 282923.27,
      category: Category.B,
    },
    {
      date: '2022-11',
      salesperson: 'Nelli Losekann',
      amount: 319656.84,
      category: Category.C,
    },
    {
      date: '2022-11',
      salesperson: 'Susi Mielcarek',
      amount: 12293.02,
      category: Category.B,
    },
    {
      date: '2022-11',
      salesperson: 'Susi Mielcarek',
      amount: 67696.02,
      category: Category.C,
    },
    {
      date: '2022-12',
      salesperson: 'Hans-Günther Bonbach',
      amount: 88716.35,
      category: Category.A,
    },
    {
      date: '2022-12',
      salesperson: 'Hans-Günther Bonbach',
      amount: 32875.6,
      category: Category.B,
    },
    {
      date: '2022-12',
      salesperson: 'Hans-Günther Bonbach',
      amount: 78367.79,
      category: Category.C,
    },
    {
      date: '2022-12',
      salesperson: 'Nelli Losekann',
      amount: 101232.9,
      category: Category.A,
    },
    {
      date: '2022-12',
      salesperson: 'Nelli Losekann',
      amount: 107592.13,
      category: Category.B,
    },
    {
      date: '2022-12',
      salesperson: 'Nelli Losekann',
      amount: 335504.82,
      category: Category.C,
    },
    {
      date: '2022-12',
      salesperson: 'Susi Mielcarek',
      amount: 0,
      category: Category.C,
    },
  ];
}

export function mockSalesFunnelData(): SalesFunnelData[] {
  return [
    {
      quotes: 2948270.14,
      quotes_converted: 927066.1,
      sold: 849101.5,
      invoiced: 783261.87,
      paid: 672213.025121,
      paid_target: 1000000,
      invoiced_target: 1176470.588235294,
      sold_target: 1307189.54248366,
      quotes_converted_target: 1452432.824981845,
      quotes_target: 4401311.590854074,
    },
  ];
}

export function mockSalesUnpaidDistributionData(): UnpaidSalesDistributionData[] {
  return [
    {
      bin_start: 0,
      bin_end: 7113.362857,
      freq_not_invoiced: 322,
      freq_not_paid_expired: 157,
      freq_not_paid_not_expired: 159,
      expired_days: 246,
    },
    {
      bin_start: 7113.362857,
      bin_end: 14226.725714,
      freq_not_invoiced: 41,
      freq_not_paid_expired: 29,
      freq_not_paid_not_expired: 12,
      expired_days: 64,
    },
    {
      bin_start: 14226.725714,
      bin_end: 21340.088571,
      freq_not_invoiced: 12,
      freq_not_paid_expired: 9,
      freq_not_paid_not_expired: 10,
      expired_days: 64,
    },
    {
      bin_start: 21340.088571,
      bin_end: 28453.451428,
      freq_not_invoiced: 4,
      freq_not_paid_expired: 4,
      freq_not_paid_not_expired: 1,
      expired_days: 34,
    },
    {
      bin_start: 28453.451428,
      bin_end: 35566.814285,
      freq_not_invoiced: 4,
      freq_not_paid_expired: 1,
      freq_not_paid_not_expired: 2,
      expired_days: 125,
    },
    {
      bin_start: 35566.814285,
      bin_end: 42680.177142,
      freq_not_invoiced: 3,
      freq_not_paid_expired: 2,
      freq_not_paid_not_expired: 0,
      expired_days: 3,
    },
    {
      bin_start: 42680.177142,
      bin_end: 49793.539999,
      freq_not_invoiced: 5,
      freq_not_paid_expired: 3,
      freq_not_paid_not_expired: 1,
      expired_days: 34,
    },
  ];
}

export function mockManufacturingPOStatusData(): POTreemapData[] {
  return [
    {
      status: POStatus.Finished,
      routing: 'BEND',
      material: 'MAT1',
      thickness: 105.0,
      estimated_time: 649000,
      nPOs: 1,
      nPOLs: 4,
    },
    {
      status: POStatus.Finished,
      routing: 'LASER-BEND',
      material: 'MAT3',
      thickness: 135.0,
      estimated_time: 852000,
      nPOs: 1,
      nPOLs: 1,
    },
    {
      status: POStatus.Finished,
      routing: 'LASER-BEND',
      material: 'MAT4',
      thickness: 105.0,
      estimated_time: 139000,
      nPOs: 1,
      nPOLs: 3,
    },
    {
      status: POStatus.FirmPlanned,
      routing: 'BEND',
      material: 'MAT5',
      thickness: 155.0,
      estimated_time: 657000,
      nPOs: 1,
      nPOLs: 8,
    },
    {
      status: POStatus.FirmPlanned,
      routing: 'LASER-BEND',
      material: 'MAT6',
      thickness: 192.0,
      estimated_time: 382000,
      nPOs: 1,
      nPOLs: 1,
    },
    {
      status: POStatus.Planned,
      routing: 'LASER',
      material: 'MAT7',
      thickness: 104.0,
      estimated_time: 275000,
      nPOs: 1,
      nPOLs: 2,
    },
    {
      status: POStatus.Planned,
      routing: 'LASER-BEND',
      material: 'MAT8',
      thickness: 196.0,
      estimated_time: 843000,
      nPOs: 1,
      nPOLs: 2,
    },
    {
      status: POStatus.Released,
      routing: 'BEND',
      material: 'MAT9',
      thickness: 96.0,
      estimated_time: 513000,
      nPOs: 1,
      nPOLs: 1,
    },
    {
      status: POStatus.Released,
      routing: 'LASER',
      material: 'MAT10',
      thickness: 105.0,
      estimated_time: 470000,
      nPOs: 1,
      nPOLs: 1,
    },
    {
      status: POStatus.Released,
      routing: 'LASER-BEND',
      material: 'MAT11',
      thickness: 93.0,
      estimated_time: 11000,
      nPOs: 1,
      nPOLs: 3,
    },
    {
      status: POStatus.Released,
      routing: 'LASER-BEND',
      material: 'MAT12',
      thickness: 153.0,
      estimated_time: 356000,
      nPOs: 1,
      nPOLs: 4,
    },
  ];
}

export function mockManufacturingReleasedPOStatus(): POTreemapData[] {
  return [
    {
      status: ReleasedPOStatus.FirmPlanned,
      routing: 'BEND',
      material: 'MAT1',
      thickness: 45.0,
      estimated_time: 728000,
      nPOs: 1,
      nPOLs: 1,
    },
    {
      status: ReleasedPOStatus.FirmPlanned,
      routing: 'BEND',
      material: 'MAT2',
      thickness: 140.0,
      estimated_time: 665000,
      nPOs: 1,
      nPOLs: 3,
    },
    {
      status: ReleasedPOStatus.FirmPlanned,
      routing: 'BEND',
      material: 'MAT3',
      thickness: 135.0,
      estimated_time: 242000,
      nPOs: 1,
      nPOLs: 2,
    },
    {
      status: ReleasedPOStatus.InProgress,
      routing: 'BEND',
      material: 'MAT4',
      thickness: 29.0,
      estimated_time: 981000,
      nPOs: 1,
      nPOLs: 6,
    },
    {
      status: ReleasedPOStatus.InProgress,
      routing: 'LASER',
      material: 'MAT5',
      thickness: 83.0,
      estimated_time: 988000,
      nPOs: 1,
      nPOLs: 1,
    },
    {
      status: ReleasedPOStatus.InProgress,
      routing: 'LASER',
      material: 'MAT6',
      thickness: 38.0,
      estimated_time: 239000,
      nPOs: 1,
      nPOLs: 3,
    },
    {
      status: ReleasedPOStatus.InProgress,
      routing: 'LASER',
      material: 'MAT7',
      thickness: 105.0,
      estimated_time: 470000,
      nPOs: 1,
      nPOLs: 4,
    },
    {
      status: ReleasedPOStatus.InShopFloor,
      routing: 'BEND',
      material: 'MAT8',
      thickness: 52.0,
      estimated_time: 282000,
      nPOs: 1,
      nPOLs: 5,
    },
    {
      status: ReleasedPOStatus.InShopFloor,
      routing: 'LASER',
      material: 'MAT9',
      thickness: 178.0,
      estimated_time: 762000,
      nPOs: 1,
      nPOLs: 6,
    },
    {
      status: ReleasedPOStatus.InShopFloor,
      routing: 'LASER',
      material: 'MAT10',
      thickness: 21.0,
      estimated_time: 171000,
      nPOs: 1,
      nPOLs: 1,
    },
    {
      status: ReleasedPOStatus.InShopFloor,
      routing: 'LASER-BEND',
      material: 'MAT11',
      thickness: 153.0,
      estimated_time: 356000,
      nPOs: 1,
      nPOLs: 2,
    },
    {
      status: ReleasedPOStatus.Planned,
      routing: 'BEND',
      material: 'MAT12',
      thickness: 26.0,
      estimated_time: 8000,
      nPOs: 1,
      nPOLs: 3,
    },
    {
      status: ReleasedPOStatus.Planned,
      routing: 'LASER',
      material: 'MAT13',
      thickness: 111.0,
      estimated_time: 338000,
      nPOs: 1,
      nPOLs: 6,
    },
    {
      status: ReleasedPOStatus.Planned,
      routing: 'LASER',
      material: 'MAT14',
      thickness: 56.0,
      estimated_time: 768000,
      nPOs: 1,
      nPOLs: 9,
    },
    {
      status: ReleasedPOStatus.Planned,
      routing: 'LASER',
      material: 'MAT15',
      thickness: 93.0,
      estimated_time: 11000,
      nPOs: 1,
      nPOLs: 1,
    },
    {
      status: ReleasedPOStatus.Prepared,
      routing: 'BEND',
      material: 'MAT16',
      thickness: 166.0,
      estimated_time: 51000,
      nPOs: 1,
      nPOLs: 4,
    },
    {
      status: ReleasedPOStatus.Prepared,
      routing: 'LASER-BEND',
      material: 'MAT17',
      thickness: 122.0,
      estimated_time: 639000,
      nPOs: 1,
      nPOLs: 6,
    },
    {
      status: ReleasedPOStatus.Produced,
      routing: 'LASER',
      material: 'MAT18',
      thickness: 182.0,
      estimated_time: 938000,
      nPOs: 1,
      nPOLs: 1,
    },
    {
      status: ReleasedPOStatus.Produced,
      routing: 'LASER-BEND',
      material: 'MAT19',
      thickness: 177.0,
      estimated_time: 952000,
      nPOs: 1,
      nPOLs: 6,
    },
    {
      status: ReleasedPOStatus.Produced,
      routing: 'LASER-BEND',
      material: 'MAT20',
      thickness: 122.0,
      estimated_time: 151000,
      nPOs: 1,
      nPOLs: 1,
    },
  ];
}

export function mockPOFinishedDueDateData(): ManufacturingPOFinishedDueDateData[] {
  return [
    {
      range_start: -22,
      range_end: -12,
      estimated_time_hours: 5.32611111096,
    },
    {
      range_start: -11,
      range_end: -1,
      estimated_time_hours: 4.1297222221,
    },
    {
      range_start: 0,
      range_end: 10,
      estimated_time_hours: 5.25444444433,
    },
    {
      range_start: 11,
      range_end: 21,
      estimated_time_hours: 4.0969444443,
    },
    {
      range_start: 22,
      range_end: 32,
      estimated_time_hours: 4.00416666652,
    },
    {
      range_start: -22,
      range_end: -12,
      estimated_time_hours: 5.71083333313,
    },
    {
      range_start: -11,
      range_end: -1,
      estimated_time_hours: 2.93361111099,
    },
    {
      range_start: 0,
      range_end: 10,
      estimated_time_hours: 2.35583333324,
    },
    {
      range_start: 11,
      range_end: 21,
      estimated_time_hours: 3.80972222209,
    },
    {
      range_start: 22,
      range_end: 32,
      estimated_time_hours: 1.3916666666,
    },
    {
      range_start: -22,
      range_end: -12,
      estimated_time_hours: 3.86111111098,
    },
    {
      range_start: -11,
      range_end: -1,
      estimated_time_hours: 2.62638888878,
    },
    {
      range_start: 0,
      range_end: 10,
      estimated_time_hours: 4.87222222209,
    },
    {
      range_start: 11,
      range_end: 21,
      estimated_time_hours: 4.26777777765,
    },
    {
      range_start: 22,
      range_end: 32,
      estimated_time_hours: 4.4480555554,
    },
    {
      range_start: -22,
      range_end: -12,
      estimated_time_hours: 4.9791666665,
    },
    {
      range_start: -11,
      range_end: -1,
      estimated_time_hours: 14.79138888846,
    },
    {
      range_start: 0,
      range_end: 10,
      estimated_time_hours: 13.8511111107,
    },
    {
      range_start: 11,
      range_end: 21,
      estimated_time_hours: 4.26638888875,
    },
    {
      range_start: 22,
      range_end: 32,
      estimated_time_hours: 1.08388888886,
    },
  ];
}

export function mockPOLStatusDueDateData(): ManufacturingPOLStatusDueDateData[] {
  return [
    {
      status: POLStatus.Planned,
      range_start: -22,
      range_end: -12,
      estimated_time_hours: 0.1,
    },
    {
      status: POLStatus.Planned,
      range_start: -11,
      range_end: -1,
      estimated_time_hours: 0.5,
    },
    {
      status: POLStatus.Planned,
      range_start: 0,
      range_end: 10,
      estimated_time_hours: 0.9,
    },
    {
      status: POLStatus.Planned,
      range_start: 11,
      range_end: 22,
      estimated_time_hours: 1.1,
    },
    {
      status: POLStatus.Planned,
      range_start: 22,
      range_end: 32,
      estimated_time_hours: 0.7,
    },
    {
      status: POLStatus.FirmPlanned,
      range_start: -22,
      range_end: -12,
      estimated_time_hours: 0.25,
    },
    {
      status: POLStatus.FirmPlanned,
      range_start: -11,
      range_end: -1,
      estimated_time_hours: 0.6,
    },
    {
      status: POLStatus.FirmPlanned,
      range_start: 0,
      range_end: 10,
      estimated_time_hours: 1.3,
    },
    {
      status: POLStatus.FirmPlanned,
      range_start: 11,
      range_end: 22,
      estimated_time_hours: 1.03,
    },
    {
      status: POLStatus.FirmPlanned,
      range_start: 22,
      range_end: 32,
      estimated_time_hours: 0.58,
    },
    {
      status: POLStatus.Prepared,
      range_start: -22,
      range_end: -12,
      estimated_time_hours: 6.92027777752,
    },
    {
      status: POLStatus.Prepared,
      range_start: -11,
      range_end: -1,
      estimated_time_hours: 4.98833333316,
    },
    {
      status: POLStatus.Prepared,
      range_start: 0,
      range_end: 10,
      estimated_time_hours: 4.63361111098,
    },
    {
      status: POLStatus.Prepared,
      range_start: 11,
      range_end: 21,
      estimated_time_hours: 4.36388888872,
    },
    {
      status: POLStatus.Prepared,
      range_start: 22,
      range_end: 32,
      estimated_time_hours: 4.79305555537,
    },
    {
      status: POLStatus.InShopFloor,
      range_start: -22,
      range_end: -12,
      estimated_time_hours: 2.13277777771,
    },
    {
      status: POLStatus.InShopFloor,
      range_start: -11,
      range_end: -1,
      estimated_time_hours: 1.17388888883,
    },
    {
      status: POLStatus.InShopFloor,
      range_start: 0,
      range_end: 10,
      estimated_time_hours: 2.56472222217,
    },
    {
      status: POLStatus.InShopFloor,
      range_start: 11,
      range_end: 21,
      estimated_time_hours: 2.86305555546,
    },
    {
      status: POLStatus.InShopFloor,
      range_start: 22,
      range_end: 32,
      estimated_time_hours: 1.8580555555,
    },
    {
      status: POLStatus.InProgressDone,
      range_start: -22,
      range_end: -12,
      estimated_time_hours: 0.45472222216,
    },
    {
      status: POLStatus.InProgressDone,
      range_start: -11,
      range_end: -1,
      estimated_time_hours: 0.26611111107,
    },
    {
      status: POLStatus.InProgressDone,
      range_start: 0,
      range_end: 10,
      estimated_time_hours: 0.36777777773,
    },
    {
      status: POLStatus.InProgressDone,
      range_start: 11,
      range_end: 21,
      estimated_time_hours: 0.35083333329,
    },
    {
      status: POLStatus.InProgressDone,
      range_start: 22,
      range_end: 32,
      estimated_time_hours: 0.28749999996,
    },
    {
      status: POLStatus.InProgressRemaining,
      range_start: -22,
      range_end: -12,
      estimated_time_hours: 0.35472222216,
    },
    {
      status: POLStatus.InProgressRemaining,
      range_start: -11,
      range_end: -1,
      estimated_time_hours: 0.66611111107,
    },
    {
      status: POLStatus.InProgressRemaining,
      range_start: 0,
      range_end: 10,
      estimated_time_hours: 1.26777777773,
    },
    {
      status: POLStatus.InProgressRemaining,
      range_start: 11,
      range_end: 21,
      estimated_time_hours: 0.85083333329,
    },
    {
      status: POLStatus.InProgressRemaining,
      range_start: 22,
      range_end: 32,
      estimated_time_hours: 0.78749999996,
    },
    {
      status: POLStatus.Produced,
      range_start: -22,
      range_end: -12,
      estimated_time_hours: 4.9791666665,
    },
    {
      status: POLStatus.Produced,
      range_start: -11,
      range_end: -1,
      estimated_time_hours: 14.79138888846,
    },
    {
      status: POLStatus.Produced,
      range_start: 0,
      range_end: 10,
      estimated_time_hours: 13.8511111107,
    },
    {
      status: POLStatus.Produced,
      range_start: 11,
      range_end: 21,
      estimated_time_hours: 4.26638888875,
    },
    {
      status: POLStatus.Produced,
      range_start: 22,
      range_end: 32,
      estimated_time_hours: 1.08388888886,
    },
  ];
}

export function mockFinishedPOEstimatedVsActualPOLData(): FinishedPOData[] {
  return [
    {
      date: moment().subtract(11, 'months').format('YYYY-MM'),
      estimated_time_hours: 4263,
      executed_time_hours: 4875,
      capacity: 5000,
    },
    {
      date: moment().subtract(10, 'months').format('YYYY-MM'),
      estimated_time_hours: 2941,
      executed_time_hours: 3372,
      capacity: 3600,
    },
    {
      date: moment().subtract(9, 'months').format('YYYY-MM'),
      estimated_time_hours: 4498,
      executed_time_hours: 4956,
      capacity: 5200,
    },
    {
      date: moment().subtract(8, 'months').format('YYYY-MM'),
      estimated_time_hours: 2546,
      executed_time_hours: 2989,
      capacity: 3200,
    },
    {
      date: moment().subtract(7, 'months').format('YYYY-MM'),
      estimated_time_hours: 3268,
      executed_time_hours: 3667,
      capacity: 4000,
    },
    {
      date: moment().subtract(6, 'months').format('YYYY-MM'),
      estimated_time_hours: 3803,
      executed_time_hours: 3799,
      capacity: 4100,
    },
    {
      date: moment().subtract(5, 'months').format('YYYY-MM'),
      estimated_time_hours: 4298,
      executed_time_hours: 4654,
      capacity: 4800,
    },
    {
      date: moment().subtract(4, 'months').format('YYYY-MM'),
      estimated_time_hours: 3498,
      executed_time_hours: 4402,
      capacity: 4900,
    },
    {
      date: moment().subtract(3, 'months').format('YYYY-MM'),
      estimated_time_hours: 4169,
      executed_time_hours: 4216,
      capacity: 4500,
    },
    {
      date: moment().subtract(2, 'months').format('YYYY-MM'),
      estimated_time_hours: 3896,
      executed_time_hours: 4150,
      capacity: 4300,
    },
    {
      date: moment().subtract(1, 'months').format('YYYY-MM'),
      estimated_time_hours: 4165,
      executed_time_hours: 4654,
      capacity: 4900,
    },
    {
      date: moment().format('YYYY-MM'),
      estimated_time_hours: 2894,
      executed_time_hours: 3136,
      capacity: 3500,
    },
  ];
}

export async function mockBendShiftStatisticsData(
  selectedDeviceIds: string[],
  selectedShiftIds: number[],
  timeSpan: FilterTimeSpanEnum,
): Promise<BendShiftStatisticsData[] | null> {
  selectedDeviceIds =
    selectedDeviceIds.length === 0
      ? devicesService.store.bendDevices().map((d) => d.deviceId)
      : selectedDeviceIds;

  let shiftModels: ShiftModel[] = [];
  try {
    const customer = await tenantsService.store.current();
    if (customer?.id) {
      shiftModels = await shiftModelsService.get(customer.id);
    }
  } catch (error) {
    Logger.error(error);
    return null;
  }

  const selectedShifts =
    selectedShiftIds.length === 0
      ? shiftsService.store.getAllByDeviceIds(selectedDeviceIds)
      : shiftsService.store.getMatchingIds(selectedShiftIds);

  const timeInSeconds: number = getMaxSecondsByTimeFilter(timeSpan);
  const speedFlowWithoutProcessing = BEND_FLOW_SPEED.filter(
    (f) => f !== BendSpeedFlowStates.Processing,
  );

  const calculatePercent = (value: number, total: number) => (value * 100) / total;

  return selectedShifts.map((shift) => {
    const dataEntries = selectedDeviceIds.map((deviceId) => {
      const productionModeSeconds = randomPartition(timeInSeconds, BEND_PRODUCTION_MODES.length);
      const speedFlowSeconds = randomPartition(timeInSeconds, speedFlowWithoutProcessing.length);

      const totalSpeedFlow = speedFlowSeconds.reduce((acc, curr) => acc + curr, 0);
      const totalProductionMode = productionModeSeconds.reduce((acc, curr) => acc + curr, 0);

      return {
        deviceId,
        deviceName: getDeviceNameByDeviceId(deviceId),
        toolChanges: Math.floor(random(0, 20)),
        weight: Math.floor(random(0, 20)),
        weightHandled: Math.floor(random(0, 20)),
        bendsHour: Math.floor(random(0, 20)),
        highFlow: speedFlowSeconds[0],
        lowFlow: speedFlowSeconds[1],
        noFlow: speedFlowSeconds[2],
        highFlowPercent: calculatePercent(speedFlowSeconds[0], totalSpeedFlow),
        lowFlowPercent: calculatePercent(speedFlowSeconds[1], totalSpeedFlow),
        noFlowPercent: calculatePercent(speedFlowSeconds[2], totalSpeedFlow),
        automatic: productionModeSeconds[0],
        semiAutomatic: productionModeSeconds[1],
        nonProductive: productionModeSeconds[2],
        automaticPercent: calculatePercent(productionModeSeconds[0], totalProductionMode),
        semiAutomaticPercent: calculatePercent(productionModeSeconds[1], totalProductionMode),
        nonProductivePercent: calculatePercent(productionModeSeconds[2], totalProductionMode),
      };
    });

    const shiftModel = shiftModels.find((sm) => sm.shifts.some((sh) => sh.id === shift.id));

    return {
      shiftId: shift.id,
      shiftName: shift.name,
      shiftModelName: shiftModel ? shiftModel.name : '',
      deviceStatistics: dataEntries,
    };
  });
}

function mockRandomDataEntry(selectedDevices: Device[]): BendPartDataEntry[] {
  return Array.from({ length: 10 }, (_, i) => {
    const device = selectedDevices[i % selectedDevices.length];
    return {
      deviceId: device.deviceId,
      deviceName: device.name,
      partName: `part-name-${i}`,
      materialName: `material-name-${i}`,
      totalBends: Math.floor(random(0, 1000)),
      weight: random(0, 20),
      flowPercent: Math.floor(random(0, 100)),
    };
  });
}

export async function mockBendRankingOfPartsData(
  selectedDeviceIds: string[],
): Promise<RankingDataOfBendPart> {
  const selectedDevices = selectedDeviceIds.map(
    (d) => devicesService.store.findByStringId(d) as Device,
  );

  return {
    bestFlowData: mockRandomDataEntry(selectedDevices),
    worstFlowData: mockRandomDataEntry(selectedDevices),
  };
}

function getDeviceNameByDeviceId(deviceid: string): string {
  const device = devicesService.store.findByStringId(deviceid);
  return device ? device.name : '';
}

export function mockBendProductivityFiguresData(): BendProductivityFiguresData[] {
  const getBucket = (hour: number) =>
    moment().set('hour', hour).set('minutes', 0).set('seconds', 0).toISOString();

  return [
    {
      name: BendProductivityFiguresName.BendCount,
      currentTotalValue: 21,
      previousTotalValue: 26,
      chartData: [
        {
          bucket: getBucket(9),
          value: 43,
        },
        {
          bucket: getBucket(10),
          value: 41,
        },
        {
          bucket: getBucket(11),
          value: 20,
        },
        {
          bucket: getBucket(12),
          value: 3,
        },
        {
          bucket: getBucket(13),
          value: 28,
        },
        {
          bucket: getBucket(14),
          value: 19,
        },
        {
          bucket: getBucket(15),
          value: 14,
        },
        {
          bucket: getBucket(16),
          value: 39,
        },
      ],
    },
    {
      name: BendProductivityFiguresName.ToolChangesCount,
      currentTotalValue: 2,
      previousTotalValue: 3,
      chartData: [
        {
          bucket: getBucket(9),
          value: 2,
        },
        {
          bucket: getBucket(10),
          value: 3,
        },
        {
          bucket: getBucket(11),
          value: 0,
        },
        {
          bucket: getBucket(12),
          value: 1,
        },
        {
          bucket: getBucket(13),
          value: 3,
        },
        {
          bucket: getBucket(14),
          value: 0,
        },
        {
          bucket: getBucket(15),
          value: 2,
        },
        {
          bucket: getBucket(16),
          value: 2,
        },
      ],
    },
    {
      name: BendProductivityFiguresName.ProducedWeight,
      currentTotalValue: 42.25,
      previousTotalValue: 55,
      chartData: [
        {
          bucket: getBucket(9),
          value: 318.48,
        },
        {
          bucket: getBucket(10),
          value: 330.88,
        },
        {
          bucket: getBucket(11),
          value: 26.9,
        },
        {
          bucket: getBucket(12),
          value: 26.71,
        },
        {
          bucket: getBucket(13),
          value: 82.08,
        },
        {
          bucket: getBucket(14),
          value: 171.18,
        },
        {
          bucket: getBucket(15),
          value: 20.6,
        },
        {
          bucket: getBucket(16),
          value: 62.03,
        },
      ],
    },
    {
      name: BendProductivityFiguresName.WeightHandledByOperator,
      currentTotalValue: 426.5,
      previousTotalValue: 351.25,
      chartData: [
        {
          bucket: getBucket(9),
          value: 913.3024607207875,
        },
        {
          bucket: getBucket(10),
          value: 1266.2774173495561,
        },
        {
          bucket: getBucket(11),
          value: 88.68708782337129,
        },
        {
          bucket: getBucket(12),
          value: 65.90398121956869,
        },
        {
          bucket: getBucket(13),
          value: 308.8020038819725,
        },
        {
          bucket: getBucket(14),
          value: 471.3174147465898,
        },
        {
          bucket: getBucket(15),
          value: 73.39989368052167,
        },
        {
          bucket: getBucket(16),
          value: 224.35451651095437,
        },
      ],
    },
  ];
}

export function mockBendProductivityFiguresChartData(
  timeSpan: FilterTimeSpanEnum,
  maxValue: number,
): BendProductivityFiguresChartData[] {
  switch (timeSpan) {
    case FilterTimeSpanEnum.Day: {
      const dayHours = 24;
      return iterable(dayHours).map((i) =>
        createBendProductivityChartDataEntry(
          `2024-08-29T${(i + 1).toString().padStart(2, '0')}:00:00.000Z`,
          maxValue,
        ),
      );
    }
    case FilterTimeSpanEnum.Week: {
      const weekDays = 7;
      return iterable(weekDays).map((i) =>
        createBendProductivityChartDataEntry(
          `2024-08-${(i + 1).toString().padStart(2, '0')}`,
          maxValue,
        ),
      );
    }
    case FilterTimeSpanEnum.Month: {
      const monthDays = 30;
      return iterable(monthDays).map((i) =>
        createBendProductivityChartDataEntry(
          `2024-08-${(i + 1).toString().padStart(2, '0')}`,
          maxValue,
        ),
      );
    }
    case FilterTimeSpanEnum.Year:
    default: {
      const yearMonths = 12;
      return iterable(yearMonths).map((i) =>
        createBendProductivityChartDataEntry(
          `2024-${(i + 1).toString().padStart(2, '0')}`,
          maxValue,
        ),
      );
    }
  }
}

function createBendProductivityChartDataEntry(
  bucket: string,
  maxValue: number,
): BendProductivityFiguresChartData {
  return {
    bucket,
    value: random(0, random(0, maxValue)),
  };
}

function random(min: number, max: number) {
  return Math.random() * (max - min) + min;
}
