
import WidgetDefinition from '@/models/Charts/widgetDefinition';
import { Component, Prop, Vue } from 'vue-property-decorator';
import Chart from '../Charts/Chart.vue';
import { abbreviateNumber, formatPercentage } from '@/utils/number';
import { isEmpty, isNil } from '@/utils/misc';
import { KpiWidgetData } from '@/models/Charts/chartsData';
import { AbstractKpiWidgetGenerator } from '@/models/Charts/abstract/abstractKpiWidgetGenerator';
import ValueTrend from '@/components/common/ValueTrend.vue';

@Component({
  components: {
    ValueTrend,
    Chart,
  },
  methods: {
    abbreviateNumber,
    formatPercentage,
    isEmpty,
    isNil,
  },
})
export default class KpiWidget extends Vue {
  @Prop({ required: true })
  private data!: KpiWidgetData[] | null;

  @Prop({ required: true })
  private generator!: AbstractKpiWidgetGenerator<any>;

  @Prop({ required: true })
  private widgetDefinition!: WidgetDefinition;

  @Prop({ required: true, type: Boolean })
  private loading!: boolean;

  @Prop({ required: true, type: Boolean })
  private isFetchingForTheFirstTime!: boolean;

  private getTotalAmount(valueField: 'currentValue' | 'previousValue'): number {
    return this.data!
      .map((dataEntry) => dataEntry[valueField])
      .reduce((total, value) => total + value, 0);
  }

  private get formattedMainFact() {
    return this.generator.formatWithUnit(abbreviateNumber(this.currentTotalAmount));
  }

  private get hasData() {
    return !isEmpty(this.data);
  }

  private get isThereTrendInfo() {
    return this.data!.some((dataItem) => dataItem.previousValue !== 0);
  }

  private get currentTotalAmount() {
    return this.getTotalAmount('currentValue');
  }

  private get previousTotalAmount() {
    return this.getTotalAmount('previousValue');
  }
}
