// --------------------------------------------------------------------------------
// <copyright file="routes.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

/*
 * We can't have Routes defined in index.ts as we need to import it in
 * Sidebar.vue without Vue.use(VueRouter) being called. Otherwise we can't mock
 * this.$router on unit tests.
 *
 * See: https://stackoverflow.com/a/55544290/3975331
 */

export enum Routes {
  // Back office
  Devices = 'devices',
  Subsidiaries = 'subsidiaries',
  SubsidiaryDetail = 'subsidiaries-detail',
  Tenants = 'tenants',
  TenantDetail = 'tenant-detail',
  ControlList = 'control-list',
  Users = 'users',
  DataPlatforms = 'dataplatforms',

  // Work Center Console
  WorkcenterOverview = 'workcenter-overview',
  Bend = 'workcenter-bend',
  Cut = 'workcenter-cut',
  CutGanttChart = 'workcenter-cut-gantt',
  Tubes = 'workcenter-tubes',

  // Technology Console
  TechnologyConsole = 'technology-console',
  TechnologyConsoleTenantDevices = 'technology-console-tenant-devices',
  TechnologyConsoleDevice = 'technology-console-device',

  // Shop Floor Console
  Manufacturing = 'shopfloor-manufacturing',
  Inventory = 'shopfloor-inventory',

  // Business Console
  BusinessConsoleOverview = 'business-console-overview',
  Quotes = 'business-quotes',
  Sales = 'business-sales',

  // eService center (formerly Care Console)
  EServiceCenter = 'e-service-center',
  EServiceCenterInbox = 'e-service-center-inbox',
  EServiceCenterCustomers = 'e-service-center-customers',
  EServiceCenterEquipment = 'e-service-center-equipment',
  EServiceCenterAlerts = 'e-service-center-alerts',
  EServiceCenterCustomerEquipment = 'e-service-center-customer-equipment',
  EServiceCenterEquipmentView = 'e-service-center-equipment-view',

  // Others
  About = 'about',
  Index = 'index',
  Login = 'login',
  LoginFailed = 'login-failed',
  LoginCallback = 'login-callback',
  Logout = 'logout',
  LoggedOut = 'logged-out',
  LogoutCallback = 'logout-callback',
}
